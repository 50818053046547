<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
        class="icon-ico-back me-2"></span>
      {{ $route.params.employeeId ? 'Ubah' : 'Tambah' }} Data Karyawan</a>

    <ValidationObserver ref="observer"> 
      <form @submit.prevent="store()">
        <ValidationProvider name="Foto" v-slot="{ errors }" rules="" ref="photo">
          <div class="d-flex">
            <div class=" position-relative">
              <div class="img-profile lg">
                <img :src="model.photo ? model.photo : '/img/user-default.jpg'" class="img-fluid">
              </div>
                <div class="upload-photo">
                  <input type="file" id="u-p" class="hidden" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                  <label for="u-p"><img src="/img/icons/camera.svg" class="img-fluid"></label>
                </div>
            </div>
          </div>
          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
        </ValidationProvider>

        <div class="row gx-3 mb-3 mt-4">
          <div class="col-sm-6">
            <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="name">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Nama</label>
                <div class="position-relative">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.name">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required" ref="gender">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Jenis Kelamin</label>
                <div class="position-relative mt-2">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="kelamin" id="laki" v-model="model.gender" value="laki">
                        <label class="text-sm cursor-pointer color-gray-100 text-medium" for="laki">
                          Laki - Laki
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="kelamin" id="perempuan" v-model="model.gender" value="perempuan">
                        <label class="text-sm cursor-pointer color-gray-100 text-medium" for="perempuan">
                          Perempuan
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required" ref="bod">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Tanggal Lahir</label>
                <div class="position-relative">
                  <div class="icn-calendar">
                    <date-picker v-model="model.bod" :config="datePickerOptions" placeholder="Pilih Tanggal"></date-picker>
                  </div>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Nomor telepon" v-slot="{ errors }" rules="required|min:8|max:15" ref="phone">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Nomor Telepon</label>
                <div class="position-relative">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.phone" @keypress="numbersOnly">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required" ref="address">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Alamat</label>
                <div class="position-relative">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.address">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Posisi" v-slot="{ errors }" rules="required" ref="roleId">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Posisi</label>
                <div class="position-relative">
                  <div class="icn-chevron">
                    <select class="form-select" v-model="model.roleId">
                      <option :value="null">Pilih Posisi</option>
                      <option value="3">Kasir</option>
                      <option value="4">Terapis</option>
                    </select>
                  </div>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Email</label>
                <div class="position-relative">
                  <input type="email" class="form-control" name="" placeholder="" v-model="model.email">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider name="Kata sandi app" v-slot="{ errors }" :rules="{ required: ($route.params.id ? false : true), min: 8 }" ref="password">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Kata Sandi App</label>
                <div class="position-relative">
                  <input type="password" id="password2" class="form-control" placeholder="" name="" v-model="model.password">
                  <span toggle="#password2" class="btn-eye"></span>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="center-content">
          <div class="col-12 col-sm-5">
            <button class="btn btn-primary w-100" type="submit">Simpan</button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <!-- <json-viewer
      :value="model"
      :expand-depth="5"
      copyable
      boxed
      sort></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      maxFileSize: 5,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      datePickerOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      model: {
        kfsId: parseInt(this.$route.params.id),
        roleId: null,
        name: null,
        photo: null,
        gender: null,
        bod: null,
        phone: null,
        email: null,
        address: null,
        password: null
      },
    }
  },
  methods: {
    show() {
      let _ = this
      _.axios.get('/kfs-employee/' + _.$route.params.employeeId)
        .then( resp => {
          const data = resp.data.data
          _.model = {
            kfsId: parseInt(this.$route.params.id),
            roleId: data.roleId,
            name: data.profile.fullName,
            photo: data.profile.photo,
            gender: data.profile.gender,
            bod: data.profile.bod,
            phone: data.profile.phone,
            email: data.email,
            address: data.profile.address,
            password: ""
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      _.model.photo = await _.upload( file, _.allowedExtension, _.maxFileSize, 'photo' )
    },
    async store() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.$route.params.employeeId ) {
        _.update()
        return
      }

      _.axios.post('/kfs-employee', _.model)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.back()
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    },
    update() {
      let _ = this
      _.axios.put('/kfs-employee/' + _.$route.params.employeeId, _.model)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.back()
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    },
  },
  mounted() {
    let _ = this
    if ( _.$route.params.employeeId ) {
      _.show()
    }
  },
}
</script>