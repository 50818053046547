<template>
  <div class="dataTables_wrapper">
    <div class="table-responsive">
      <table class="table dataTable table-main w-100">
        <thead>
          <tr>
            <th v-for="(column, index) in table.options.columns" 
              :class="!column.sortable ? 'no-sort' : ( currentSort == column.reference ? ( currentOrder == 'asc' ? 'sorting_asc' : 'sorting_desc' ) : 'sorting' )" 
              @click="column.sortable ? sort( column.reference ) : ''"
              :key="index" class="valign-middle">
              <div class="check-table d-flex" v-if="column.name == 'checkbox'">
                <input type="checkbox" id="check-all" name="select" class="hidden" @click="selectAll($event)" :checked="isAllChecked">
                <label for="check-all"></label>
              </div>
              <span v-else>{{column.name}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, key) in table.data.rows" :key="key">
            <td v-for="(row, krow) in table.options.rows" :key="krow">
              <div class="check-table d-flex" v-if="row.type == 'checkbox'">
                <input type="checkbox" :id="data.id" name="select" class="hidden" @click="select(data.id, $event)" :checked="selected.some((obj)=> obj == data.id)">
                <label :for="data.id"></label>
              </div>
              <div v-else-if="row.type == 'link'">
                <router-link :to="row.url + data[row.url_reference]" class="text-decoration-underline color-tiffany">{{ getValue(data, row.reference) }}</router-link>
              </div>
              <div v-else-if="row.type == 'badgeCustomer'">
                <div class="status text-sm" :class="getValue(data, row.reference) == true ? 'bg-lightTosca color-darkTosca' : ''">{{ (getValue(data, row.reference) == true ? 'Baru' : '-' ) }}</div>
              </div>
              <div v-else-if="row.type == 'datetime'">
                {{ getValue(data, row.reference) | moment('YYYY-MM-DD HH:mm:ss')}}
              </div>
              <div v-else-if="row.type == 'date'">
                {{ getValue(data, row.reference) | moment('DD MMMM YYYY')}}
              </div>
              <div v-else-if="row.type == 'statusDownpayment'">
                {{ getValue(data, row.reference) == true ? 'Paid' : 'Not Paid'}}
              </div>
              <div v-else-if="row.type == 'currency'">
                {{ (getValue(data, row.reference) != '-' ? getValue(data, row.reference) : 0) | currency }}
              </div>
              <div v-else-if="row.type == 'treatments'">
                {{ getValue(data, row.reference) != '-' ? getValue(data, row.reference).map( x => x.name ).join(", ") : getValue(data, row.reference) }}
              </div>
              <div v-else-if="row.type == 'medicalRecordDetail'">
                <a class="text-xs text-medium detail-medical color-red-100" @click="setMedicalRecordDetail(data)">Lihat Detail</a>
              </div>
              <span v-else v-html="getValue(data, row.reference)"></span>
            </td>
          </tr>
          <tr v-if="!table.data.rows || table.data.rows.length == 0">
            <td :colspan="table.options.columns.length" class="text-center">Tidak ada data</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination :data="table.data" v-if="table.data.rows && table.data.rows.length != 0"/>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'

export default {
  components: {
    Pagination
	},
  props: [ 'table' ],
  computed: {
    isAllChecked: function() {
      return this.selected.length == (this.table.data && this.table.data.rows ? this.table.data.rows.length : 0)
    }
  },
  data() {
    return {
      currentSort: '',
      currentOrder: '',
      selected: []
    }
  },
  methods: {
    getValue( data, reference ) {
      let splitReference = reference.split('.')
      let str = data
      splitReference.forEach(reference => {
        if ( str != null && str[reference] ) {
          str = str[reference]
        } else {
          str = null
        }
      });

      return str ? str : "-"
    },
    sort( column ) {
      let _ = this

      if ( !_.table.data.rows || _.table.data.rows.length == 0 ) return

      if ( _.currentSort == column ) {
        if ( _.currentOrder == 'desc' ) {
          _.currentSort = ''
          _.currentOrder = ''
        } else {
          _.currentOrder = _.currentOrder == 'asc' ? 'desc' : 'asc'
        }
      } else {
        _.currentSort = column
        _.currentOrder = 'asc'
      }

      _.$root.$emit('changeSortBy', _.currentSort + '.' + _.currentOrder);
    },
    selectAll(event){
      let _ = this
      if (event.target.checked) {
        _.table.data.rows.forEach(elm => {
          _.selected.push( elm.id )
        });
      } else {
        _.selected = []
      }
    },
    select(value, event){
      let _ = this
      if (event.target.checked) {
        _.selected.push(value)                
      } else {
        const index = _.selected.indexOf(value);
        if (index > -1) {
          _.selected.splice(index, 1);
        }
      }
    },
    setMedicalRecordDetail( data ) {
      this.$root.$emit('setMedicalRecordDetail', data)
    }
  },
  watch: {
    selected: function() {
      this.$root.$emit('changeSelected', this.selected);
    }
  }
}
</script>