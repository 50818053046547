<template>
  <div class="col-xl-4 col-lg-6">
    <router-link :to="'/home/po/' + data.id" class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="d-flex align-items-center col-6">
          <div class="bullet" :class="'bg-' + orderStatusColor(data.status)"></div>
          <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + orderStatusColor(data.status)">{{ orderStatusText(data.status) }}</div>
        </div>
        <div class="col-6 text-hidden text-sm color-gray-60 text-right">{{ data.poNumber }}</div>
      </div>
      <div class="text-xs text-italic color-gray-80 text-hidden" v-if="data.status != -3 && orderStatusSubtitle(data.status)">{{ orderStatusSubtitle(data.status) }}</div>
      <div class="text-xs text-italic color-gray-80 text-hidden" v-else-if="orderStatusComplainText(data.statusComplain)">{{orderStatusComplainText(data.statusComplain)}}</div>
      <hr class="dashed">
      <div class="text-sm text-medium color-gray-100 text-hidden">{{ data.requestBy }}, {{ data.requestBySince }}
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>