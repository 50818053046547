<template>
  <div>
    <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="state == 'berjalan' ? 'active' : ''" id="treatment" data-bs-toggle="tab" data-bs-target="#treatment-pane"
          type="button" role="tab" aria-controls="treatment-pane" aria-selected="true" @click="state = 'berjalan'">Treatment Berjalan</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="state == 'pengajuan' ? 'active' : ''" id="treatment-tab" data-bs-toggle="tab" data-bs-target="#pengajuan-tab-pane"
          type="button" role="tab" aria-controls="treatment-tab-pane" aria-selected="false" @click="state = 'pengajuan'">Pengajuan</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade" :class="state == 'berjalan' ? 'show active' : ''" id="treatment-pane" role="tabpanel" aria-labelledby="treatment-tab" tabindex="0">
        <TreatmentBerjalan v-if="state == 'berjalan'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'pengajuan' ? 'show active' : ''" id="pengajuan-tab-pane" role="tabpanel" aria-labelledby="pengajuan-tab" tabindex="0">
        <TreatmentPengajuan v-if="state == 'pengajuan'"/>
      </div>
    </div>
  </div>
</template>
<script>
import TreatmentBerjalan from './berjalan/Index.vue'
import TreatmentPengajuan from './pengajuan/Index.vue'

export default {
  components: {
    TreatmentBerjalan,
    TreatmentPengajuan
  },
  data() {
    return {
      state: 'berjalan',
    }
  },
}
</script>