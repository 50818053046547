<template>
  <div>
    <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="s-p" data-bs-toggle="tab" data-bs-target="#s-p-pane" type="button"
          role="tab" aria-controls="s-p-pane" aria-selected="true">Stok Produk</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="s-i-tab" data-bs-toggle="tab" data-bs-target="#s-i-tab-pane" type="button"
          role="tab" aria-controls="s-i-tab-pane" aria-selected="false">Stok
          Ingredients</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="s-p-pane" role="tabpanel" aria-labelledby="s-p-tab" tabindex="0">
        <div class="row gx-3">
          <div class="col-xl-4 col-lg-6" v-for="product in productOutOfStocks" :key="product.id">
            <div class="bg-white d-block rounded-12 p-3 mb-3">
              <div class="col d-flex align-items-center justify-content-between mb-3">
                <img :src="product.images && product.images.length > 0 ? product.images[0] : '/img/product-1.png'"
                  class="treatment rounded-2 border-1 border-solid border-color-gray">
                <div class="col-10">
                  <div class="text-sm text-medium">{{ product.productName ? product.productName : '-' }}</div>
                  <ul class="item-info mb-0">
                    <li><span class="text-xs color-red-100 text-semiBold">{{ parseInt(product.price) | currency }}</span></li>
                    <li><span class="text-xs text-medium color-gray-60">Stok {{ product.stock | numFormat }}</span></li>
                  </ul>
                </div>
              </div>
              <router-link to="/home/po/add?type=1" class="btn btn-primary w-100 py-2">Buat Pesanan</router-link>
            </div>
          </div>
        </div>
        <div class="row gx-3">
          <div class="col-xl-4 col-lg-6" v-for="(product, index) in products" :key="product.id" ref="items">
            <div class="bg-white d-block rounded-12 p-3 mb-3">
              <div class="col d-flex align-items-center justify-content-between">
                <img :src="product.images && product.images.length > 0 ? product.images[0] : '/img/product-1.png'"
                  class="treatment rounded-2 border-1 border-solid border-color-gray">
                <div class="col-10">
                  <div class="text-medium">{{ product.productName ? product.productName : '-' }}</div>
                  <ul class="list-group item-info mb-0">
                    <li class="list-group-item"><span class="text-medium color-gray-60">Stok {{ product.stock | numFormat }}</span></li>
                    <li class="list-group-item">
                      <div class="input-group input-group-sm">
                        <div class="input-group-sm input-group-prepend">
                          <span class="input-group-text">Rp</span>
                      </div>
                      <input type="text" class="form-control" aria-label="" name="price" v-model="products[index].kfsPrice">
                      <div class="input-group-sm input-group-append">
                        <button class="btn btn-xs btn-danger" type="button" @click="changePrice(index)"><span class="icon-ico-check text-md"></span></button>
                      </div>
                    </div>
                  </li>  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="text-center text-sm" v-if="products.length == 0 && productOutOfStocks.length == 0">Tidak ada produk</p>
        <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
          <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
        </p>
      </div>
      <div class="tab-pane fade" id="s-i-tab-pane" role="tabpanel" aria-labelledby="s-i-tab" tabindex="0">
        <div class="row gx-3">
          <div class="col-xl-4 col-lg-6" v-for="ingredient in ingredientOutOfStocks" :key="ingredient.id">
            <div class="bg-white d-block rounded-12 p-3 mb-3">
              <div class="text-sm text-medium">{{ ingredient.productName ? ingredient.productName : '-' }}</div>
              <ul class="item-info ">
                <li><span class="text-xs text-medium color-lightRed">Habis</span></li>
                <li><span class="text-xs color-gray-60 text-semiBold">Tersisa {{ ingredient.stock | numFormat }} produk</span></li>
              </ul>
              <router-link to="/home/po/add?type=2" class="btn btn-primary w-100 py-2">Buat Pesanan</router-link>
            </div>
          </div>
        </div>

        <div class="row gx-3">
          <div class="col-xl-4 col-lg-6" v-for="ingredient in ingredients" :key="ingredient.id">
            <div class="bg-white d-block rounded-12 p-3 mb-3">
              <div class="text-sm text-medium">{{ ingredient.productName ? ingredient.productName : '-' }}</div>
              <span class="text-xs text-medium color-gray-60">Tersedia {{ ingredient.stock | numFormat }} produk</span>
            </div>
          </div>
        </div>

        <p class="text-center text-sm" v-if="ingredients.length == 0 && ingredientOutOfStocks.length == 0">Tidak ada bahan baku</p>
        <p class="text-center text-sm" v-if="ingredients.length > 0 && ingredientCurrentPage < ingredientTotalPage">
          <a class="color-gray-100" @click="getIngredient( ingredientCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',

      productOutOfStocks: [],

      ingredients: [],
      ingredientCurrentPage: 1,
      ingredientTotalPage: 1,
      ingredientLimit: 10,
      ingredientSortby: 'id.desc',
      ingredientSearch: '',

      ingredientOutOfStocks: [],
    }
  },
  methods: {
    getProduct( page ) {
      let _ = this
      _.axios.get('/kfs-inventory'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
          + '&kfsId=' + _.$route.params.id
          + '&type=1'
        )
        .then( resp => {
          const data = resp.data.data
          _.productCurrentPage = data.currentPage
          _.productTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.products.push({ ...elm })
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getProductOutOfStock() {
      let _ = this
      _.axios.get('/kfs-inventory/out-of-stock'
          + '?kfsId=' + _.$route.params.id
        )
        .then( resp => {
          _.productOutOfStocks = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getIngredient( page ) {
      let _ = this
      _.axios.get('/kfs-inventory/ingredients'
          + '?page=' + page
          + '&limit=' + _.ingredientLimit
          + '&sortBy=' + _.ingredientSortby
          + '&search=' + _.ingredientSearch
          + '&kfsId=' + _.$route.params.id
        )
        .then( resp => {
          const data = resp.data.data
          _.ingredientCurrentPage = data.currentPage
          _.ingredientTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.ingredients.push({ ...elm })
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getIngredientOutOfStock() {
      let _ = this
      _.axios.get('/kfs-inventory/ingredients/out-of-stock'
          + '?kfsId=' + _.$route.params.id
        )
        .then( resp => {
          _.ingredientOutOfStocks = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    changePrice(key) {
      let _ = this

      if(parseInt(_.products[key].kfsPrice) < parseInt(_.products[key].price)) {
        _.$notify({
            type: 'error',
            title: 'Perhatian : Harga jual',
            text: 'tidak boleh lebih rendah dari ' + _.products[key].price,
        });
        return;
      }
      _.axios.put('/kfs-inventory/price/' + _.products[key].idStock, {
        price: parseInt(_.products[key].kfsPrice)
      })
        .then( resp => {
          if ( resp.data.success ) {
            _.$notify({
              type: 'success',
              title: "Successfully update price"
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        });
    },
  },
  mounted() {
    this.getProduct(1)
    this.getProductOutOfStock()
    this.getIngredient(1)
    this.getIngredientOutOfStock()
  },
}
</script>