<template>
  <div>
    <router-link to="/" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Histori Poin
    </router-link>

    <div class="content-inventory">
      <div class="row gx-lg-5" v-if="kfses.length > 0">
        <div class="col-lg-4 border-inventory">
          <ul class="nav nav-pills mb-3" id="inventory-tab" role="tablist">
            <li class="nav-item mb-3" role="presentation" v-for="kfs in kfses" :key="kfs.id">
              <button class="nav-link" :class="{ active: selectedKfs && selectedKfs.id == kfs.id }" id="material-tab" data-bs-toggle="pill" data-bs-target="#material" @click="selectedKfs = kfs"
                type="button" role="tab" aria-controls="material" aria-selected="true">
                <div class="d-flex align-items-center">
                  <div class="img-place">
                    <img :src="kfs.photo">
                  </div>
                  <div class="col ms-3">
                    <div class="text-sm text-medium color-gray-100 ">{{ kfs.name }}</div>
                    <div class="text-sm color-gray-60 ">{{ kfs.totalPoint + kfs.initialPoin - kfs.totalUsedPoin | numFormat }} Poin</div>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <div class="row gx-xxl-5 mb-4">
            <div class="col-lg-12">
              <div class="bg-poin p-4 rounded-3 d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex">
                  <div class="text-xs text-medium color-white">Poin</div>
                  <div class="text-xs text-hidden color-red-40 text-italic ms-3"></div>
                </div>
                <div class="d-flex align-items-center">
                  <img src="/img/icn-coin.png" class="img-fluid">
                  <div class="text-xs text-bold mx-2 color-white">{{ (selectedKfs.totalPoint + selectedKfs.initialPoin - selectedKfs.totalUsedPoin) | numFormat }}</div>
                </div>
              </div>
              <div class="text-xs color-gray-80 text-italic" v-if="selectedKfs.expiredPoin && selectedKfs.expiredPoin >= today">{{ (selectedKfs.poinExpired + selectedKfs.initialPoin - selectedKfs.totalUsedPoin) | numFormat }} poin akan kadaluarsa {{ selectedKfs.expiredPoin | moment('DD MMMM YYYY') }}</div>
              <hr>
              <div class="text-sm color-gray-80 text-italic">Untuk menukarkan poin silahkan hubungi Kezia Pusat</div>
            </div>
          </div>

          <div class="row gx-3">
            <div class="col-xl-4 col-sm-6 col-lg-4" v-for="point in points" :key="point.id">
              <div class="bg-white rounded-10 p-3 mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <router-link :to="'/home/po/' + point.id" class="text-xs text-medium col-6 text-hidden">{{ point.noOrder }}</router-link>
                  <div class="text-xs color-gray-60">{{ point.date | moment('DD MMM YYYY') }}</div>
                </div>
                <hr class="dashed">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-60">Poin didapat</div>
                  <div class="text-xs col-auto text-bold d-flex align-items-center color-yellow">
                    <span><img src="/img/icn-coin.png" class="img-fluid"> </span><span class="ms-2">{{ point.poin | numFormat }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-center text-sm" v-if="points.length == 0">Tidak ada histori poin</p>
          <p class="text-center text-sm" v-if="points.length > 0 && pointCurrentPage < pointTotalPage">
            <a class="color-gray-100" @click="getPoint( pointCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
          </p>
        </div>
      </div>
      <p class="text-center text-sm" v-else>Tidak ada kfs</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      kfses: [],
      kfsCurrentPage: 1,
      kfsTotalPage: 1,
      kfsLimit: 10,
      kfsSortby: 'id.desc',
      kfsSearch: '',
      kfsTotal: 0,

      selectedKfs: null,

      points: [],
      pointCurrentPage: 1,
      pointTotalPage: 1,
      pointLimit: 10,
      pointSortby: 'id.desc',
      pointSearch: '',
      pointTotal: 0,

      today: this.$moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    getKfs( page ) {
      let _ = this
      
      _.axios.get('/me/kfs'
          + '?page=' + page
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsTotal = data.count
          _.kfsCurrentPage = data.currentPage
          _.kfsTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.kfses.push({ ...elm })
            });
          }

          if ( _.kfses.length > 0 ) {
            _.selectedKfs = _.kfses[0]
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getKfsPoin( kfs, page ) {
      let _ = this
      
      _.axios.get('/me/poins'
          + '?page=' + page
          + '&limit=' + _.pointLimit
          + '&sortBy=' + _.pointSortby
          + '&kfsId=' + kfs.id
        )
        .then( resp => {
          const data = resp.data.data
          _.pointTotal = data.count
          _.pointCurrentPage = data.currentPage
          _.pointTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.points.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    let _ = this
    _.getKfs(1)
  },
  watch: {
    selectedKfs: function() {
      this.points = []
      this.getKfsPoin(this.selectedKfs, 1)
    }
  }
}
</script>