<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="bg-white d-block rounded-12 p-3 mb-3">
          <div class="mb-3">
            <div class="text-xs color-gray-40">Tanggal Bergabung</div>
            <div class="text-sm text-medium">{{ model.createdAt | moment('DD MMMM YYYY')}}</div>
          </div>
          <div class="mb-3">
            <div class="text-xs color-gray-40">Nomor Telepon</div>
            <div class="text-sm text-medium">{{ model.phone }}</div>
          </div>
          <div class="mb-0">
            <div class="text-xs color-gray-40">Alamat</div>
            <div class="text-sm text-medium">{{ model.address }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {}
    }
  },
  methods: {
    showKfs() {
      let _ = this

      _.axios.get('/kfs/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.showKfs()
  },
}
</script>