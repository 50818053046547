<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span>
      Purchase Order
    </a>

    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="col">
        <div class="position-relative">
          <div class="form-search">
            <input type="text" class="form-control" v-debounce:300.lock="searchOrder" placeholder="Cari nomor order" value="" v-model="orderSearch">
          </div>
        </div>
      </div>
      <div class="col-auto ms-3">
        <a @click="exportOrder()" class="text-base filter text-medium">Export</a>
      </div>
    </div>

    <nav>
      <div class="nav nav-tabs border-0" id="information-tab" role="tablist">
        <button class="nav-link" :class="orderStatus == 0 ? 'active': ''" id="bantuan-tab" data-bs-toggle="tab" data-bs-target="#bantuan" type="button"
          role="tab" aria-controls="bantuan" aria-selected="true" @click="orderStatus = 0">Pengajuan</button>
        <button class="nav-link" :class="[1,2,3,4,'allDiterima','allDiproses'].includes(orderStatus) ? 'active': ''" id="tentang-kfs-tab" data-bs-toggle="tab" data-bs-target="#tentang-kfs" type="button"
          role="tab" aria-controls="tentang-kfs" aria-selected="false" @click="orderStatus = 'allDiterima'">Diterima</button>
        <button class="nav-link" :class="orderStatus == -2 ? 'active': ''" id="syarat-tab" data-bs-toggle="tab" data-bs-target="#syarat" type="button"
          role="tab" aria-controls="syarat" aria-selected="false" @click="orderStatus = -2">Ditolak</button>
        <button class="nav-link" :class="orderStatus == -1 ? 'active': ''" id="cancel-tab" data-bs-toggle="tab" data-bs-target="#cancel" type="button"
          role="tab" aria-controls="cancel" aria-selected="false" @click="orderStatus = -1">Dibatalkan</button>
        <button class="nav-link" :class="orderStatus == -3 ? 'active': ''" id="komplain-tab" data-bs-toggle="tab" data-bs-target="#komplain" type="button"
          role="tab" aria-controls="komplain" aria-selected="false" @click="orderStatus = -3">Komplain</button>
      </div>
    </nav>
    <div class="tab-content pt-4">
      <div class="tab-pane fade" :class="orderStatus == 0 ? 'show active': ''" id="bantuan" role="tabpanel" aria-labelledby="bantuan-tab" tabindex="0"></div>
      <div class="tab-pane fade" :class="[1,2,3,4,'allDiterima','allDiproses'].includes(orderStatus) ? 'show active': ''" id="tentang-kfs" role="tabpanel" aria-labelledby="tentang-kfs-tab" tabindex="0">
        <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="orderStatus == 'allDiterima' ? 'active': ''" id="all" data-bs-toggle="tab" data-bs-target="#all-pane" type="button" @click="orderStatus = 'allDiterima'"
              role="tab" aria-controls="all-pane" aria-selected="true">Semua</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="orderStatus == 1 ? 'active': ''" id="waiting-tab" data-bs-toggle="tab" data-bs-target="#waiting-tab-pane" @click="orderStatus = 1"
              type="button" role="tab" aria-controls="waiting-tab-pane" aria-selected="false">Pembayaran</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="orderStatus == 'allDiproses' ? 'active': ''" id="layanan-tab" data-bs-toggle="tab" data-bs-target="#layanan-tab-pane" @click="orderStatus = 'allDiproses'"
              type="button" role="tab" aria-controls="layanan-tab-pane" aria-selected="false">Proses</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="orderStatus == 4 ? 'active': ''" id="selesai-tab" data-bs-toggle="tab" data-bs-target="#selesai-tab-pane" @click="orderStatus = 4"
              type="button" role="tab" aria-controls="selesai-tab-pane" aria-selected="false">Selesai</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade" :class="orderStatus == 'allDiterima' ? 'show active': ''" id="all-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0"></div>
          <div class="tab-pane fade" :class="orderStatus == 2 ? 'show active': ''" id="waiting-tab-pane" role="tabpanel" aria-labelledby="waiting-tab" tabindex="0"></div>
          <div class="tab-pane fade" :class="orderStatus == 'allDiproses' ? 'show active': ''" id="layanan-tab-pane" role="tabpanel" aria-labelledby="layanan-tab" tabindex="0"></div>
          <div class="tab-pane fade" :class="orderStatus == 4 ? 'show active': ''" id="selesai-tab-pane" role="tabpanel" aria-labelledby="selesai-tab" tabindex="0"></div>
        </div>
      </div>
      <div class="tab-pane fade" :class="orderStatus == -2 ? 'show active': ''" id="syarat" role="tabpanel" aria-labelledby="syarat-tab" tabindex="0"></div>
      <div class="tab-pane fade" :class="orderStatus == -1 ? 'show active': ''" id="cancel" role="tabpanel" aria-labelledby="cancel-tab" tabindex="0"></div>
      <div class="tab-pane fade" :class="orderStatus == -3 ? 'show active': ''" id="komplain" role="tabpanel" aria-labelledby="komplain-tab" tabindex="0"></div>
    </div>

    <div class="row gx-3" v-if="orders.length > 0">
      <CardPO v-for="order in orders" :key="order.id" :data="order"/>
    </div>
    <p class="text-center text-sm" v-if="orders.length == 0">Tidak ada order</p>
    <p class="text-center text-sm" v-if="orders.length > 0 && orderCurrentPage < orderTotalPage">
      <a class="color-gray-100" @click="getOrder( orderCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>
  </div>
</template>

<script>
import CardPO from './components/CardPO.vue'

export default {
  components: {
    CardPO,
  },
  data() {
    return {
      orders: [],
      orderCurrentPage: 1,
      orderTotalPage: 1,
      orderLimit: 10,
      orderSortby: 'id.desc',
      orderSearch: '',
      orderStatus: 0,
    }
  },
  methods: {
    searchOrder() {
      this.orders = []
      this.getOrder(1)
    },
    getOrder( page ) {
      let _ = this

      let status = _.orderStatus
      if (_.orderStatus == 'allDiterima') {
        status = 'in:1,2,3,4'
      } else if (_.orderStatus == 'allDiproses') {
        status = 'in:2,3'
      }

      _.axios.get('/purchase-orders'
          + '?page=' + page
          + '&limit=' + _.orderLimit
          + '&sortBy=' + _.orderSortby
          + '&status=' + status
          + (_.orderSearch ? '&search=' + _.orderSearch : '')
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.orderCurrentPage = data.currentPage
            _.orderTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.orders.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    exportOrder() {
      let _ = this

      let status = _.orderStatus
      if (_.orderStatus == 'allDiterima') {
        status = 'in:1,2,3,4'
      } else if (_.orderStatus == 'allDiproses') {
        status = 'in:2,3'
      }

      _.axios.get('/purchase-orders/exports'
          + '?status=' + status
          + (_.orderSearch ? '&search=' + _.orderSearch : '')
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'PURCHASE-ORDER-' + _.$moment().format('YYYYMMDDHHmmsss') + '.xlsx'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    if ( this.$route.query.orderStatus ) {
      this.orderStatus = this.$route.query.orderStatus
    } else {
      this.getOrder(1)
    }
  },
  watch: {
    orderStatus: function() {
      this.orders = []
      this.getOrder(1)
    }
  }
}
</script>