<template>
  <div>
    <section v-if="state == 'detail'">
      <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Detail Order</a>

      <div class="row ">
        <div class="col-lg-7 mb-4">
          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
            <div class="d-flex align-items-center">
              <div class="bullet" :class="'bg-' + salesStatusColor(model.status)"></div>
              <div class="text-semiBold text-sm ms-3" :class="'color-' + salesStatusColor(model.status)">{{ salesStatusText(model.status) }}</div>
            </div>
            <div class="text-xs text-italic color-gray-80 text-hidden mt-2" v-if="salesStatusSubtitle(model.status)">{{ salesStatusSubtitle(model.status) }}</div>
            <div class="text-xs text-italic color-gray-80 text-hidden mt-2" v-else-if="orderStatusComplainText(model.statusComplain)">{{ orderStatusComplainText(model.statusComplain) }}</div>
          </div>

          <div class="bg-white d-flex justify-content-between align-items-center rounded-12 p-3  mb-3">
              <div class="text-sm  color-gray-60 text-hidden">Nomor Order </div>
              <div class="text-sm text-medium color-gray-100 text-hidden">{{ model.poNumber }}</div>
            </div>

          <div class="bg-white d-block rounded-12 p-3 mb-3" v-if="model.acceptedBy && model.status != -2">
            <div class="text-sm  color-gray-60 text-hidden">Permintaan Untuk </div>
            <div class="text-sm text-medium color-gray-100 text-hidden">{{ model.acceptedBy.kfs.name }} </div>
          </div>
          <div class="bg-white d-block rounded-12 p-3 mb-3" v-else-if="model.acceptedTo && model.status != -2">
            <div class="text-sm  color-gray-60 text-hidden">Permintaan Untuk </div>
            <div class="text-sm text-medium color-gray-100 text-hidden">{{ model.acceptedTo.kfs ? model.acceptedTo.kfs.name : '-' }} </div>
          </div>

          <div class="bg-white d-block rounded-12 p-3  mb-3">
            <div class="text-sm  color-gray-60 text-hidden">Permintaan Dari </div>
            <div class="text-sm text-medium color-gray-100 text-hidden">{{ model.requestBy.name }} </div>
            <div class="d-flex align-items-center pt-2">
              <span class="icon-ico-phone color-green"></span>
              <div class="text-sm text-medium ps-2">{{ model.requestBy.phone }}</div>
            </div>
            <hr class="dashed">
            <div class="text-xs color-gray-60">{{ model.requestBy.address }}</div>
          </div>

          <section v-if="[0].includes(model.status)">
            <div class="text-sm text-semiBold mb-3">Rincian Order </div>
            <!-- <p class="text-xs text-italic">Silakan ceklis produk untuk memastikan ketersedian produk</p> -->
            <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray">
              <div class="d-flex justify-content-between mb-3" v-for="product in selectedProducts.filter( x => x.qtyRequest > 0 || x.qty > 0 )" :key="product.id">
                <div class="col align-self-center">
                  <div class="text-xs color-gray-80 mb-1">{{ product.name }}</div>
                  <div class="text-xs color-gray-80">{{ product.qty }} x {{ product.acceptedUnitPrice | currency }} 
                    <span class="color-red-100" v-if="product.qty != product.qtyRequest">(Permintaan: {{ product.qtyRequest }})</span></div>
                </div>
                <div class="col-auto">
                  <div class="text-xs color-gray-80">Stok saat ini: {{ currentStock( product.id ) | numFormat }}</div>
                  <div class="d-flex justify-content-center align-items-center">
                    <button @click="addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                    </button>
                    <input type="number" class="form-control input-number" :min="0" :max="currentStock( product.id )" :disabled="currentStock( product.id ) == 0"
                      v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                    <button @click="addOrSubProduct(product, 'add')" :disabled="product.qty >= currentStock( product.id )"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                    </button>
                  </div>
                </div>
              </div>

              <button class="btn btn-border w-100" @click="state = 'product'">+ Tambah Produk Lainnya</button>

              <hr class="dashed">

              <div class="d-flex justify-content-between align-items-center">
                <div class="col">
                  <div class="text-xs color-gray-40">Total Keseluruhan </div>
                </div>
                <div class="col-auto" v-if="!isLoading">
                  <div class="text-xs color-red-100 text-semiBold">{{ totalPrice | currency }}</div>
                </div>
              </div>
            </div>
          </section>

          <section v-if="[-3].includes(model.status)">
            <div class="text-sm text-semiBold mb-3">Rincian Komplain </div>
            <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
              <div class="d-flex justify-content-between mb-3" v-for="item in model.complaints[0].items" :key="item.id">
                <div class="col">
                  <div class="text-xs color-gray-80 mb-1">{{ item.name }}</div>
                  <div class="text-xs color-gray-80">{{ item.qty | numFormat }} x {{ item.unitPrice | currency }}</div>
                </div>
                <div class="col-auto">
                  <div class="text-xs color-gray-100 text-medium">{{ item.qty * item.unitPrice | currency }}</div>
                </div>
              </div>

              <hr class="dashed">

              <div class="text-xs color-gray-80 text-italic">{{ model.complaints[0].complain }}</div>

              <hr class="dashed">

              <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-3 border-1 border-solid border-color-gray mb-3" v-for="(attachment, index) in model.complaints[0].attachments" :key="index">
                <div class="d-flex justify-content-between">
                  <div class="col-auto align-self-center">
                    <a :href="attachment" target="_blank">
                      <img :src="!['png', 'jpg', 'jpeg'].includes(attachment.split('.').pop().toLowerCase()) ? '/img/ico_attachment.png' : attachment" class="img-fluid rounded-12" style="width: 50px; height: 50px; object-fit: cover;">
                    </a>
                  </div>
                  <div class="col pe-3 ps-3 align-self-center">
                    <div class="text-xs color-gray-80">
                      <a :href="attachment" target="_blank">{{ attachment.split('/').pop() }}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!--End List Product-->

        <div class="col-lg-5" v-if="[0].includes(model.status)">
          <div class="mb-3">
            <label class="text-xs color-gray-40 mb-2">Biaya Tambahan </label>
            <div class="position-relative">
              <div class="icn-left ">
                <vue-numeric @keypress.native="numbersOnly" v-model="addPayment" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="text-xs color-gray-40 mb-2">Keterangan Biaya Tambahan </label>
            <div class="position-relative">
              <input type="text" class="form-control" name="" placeholder="" v-model="addPaymentNote">
            </div>
          </div>

          <div class="row gx-3">
            <div class="col-6">
              <button class="btn btn-border-primary cancel w-100">Tolak</button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 done" :disabled="selectedProducts.filter( x => x.qty > 0 ).length == 0">Terima</button>
            </div>
          </div>
        </div>
        <div class="col-lg-5" v-if="[1,2,3,4].includes(model.status)">
          <div class="text-sm text-semiBold mb-3">Rincian Order </div>
          <!-- <p class="text-xs text-italic">Silakan ceklis produk untuk memastikan ketersedian produk</p> -->
          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
            <div class="d-flex justify-content-between mb-3" v-for="product in model.products" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.detail.name }}</div>
                <div class="text-xs color-gray-80">{{ product.qty }} x {{ product.unitPrice | currency }}</div>
              </div>
              <div class="col-auto">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="text-xs color-gray-100 text-medium">{{ product.qty * product.unitPrice | currency }}</div>
                </div>
              </div>
            </div>
            <section v-if="model.addPayment">
              <hr class="dashed">
              <div class="d-flex justify-content-between ">
                <div class="col">
                  <div class="text-xs color-gray-80 mb-1">Biaya Tambahan </div>
                  <div class="text-xs text-italic color-gray-80">{{ model.addPaymentNote }}</div>
                </div>
                <div class="col-auto">
                  <div class="text-xs color-gray-100 text-medium">{{ model.addPayment | currency }}</div>
                </div>
              </div>
            </section>
            <hr class="dashed">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="text-xs color-gray-40">Total Pembayaran </div>
              </div>
              <div class="col-auto">
                <div class="text-xs color-red-100 text-semiBold">{{ totalPembayaran | currency }}</div>
              </div>
            </div>
          </div>

          <div class="row" v-if="[1].includes(model.status)">
            <div class="col-lg-12 col-12 mt-3">
              <button class="btn btn-border-primary w-100" @click="downloadInvoice()">Download Invoice</button>
            </div>
            <div class="col-lg-12 col-12 mt-3">
              <button class="btn btn-primary w-100 done">Konfirmasi Pembayaran</button>
            </div>
          </div>

          <button class="btn btn-primary w-100 done" v-if="[2].includes(model.status)">Kirim Barang</button>
        </div>
        <div class="col-lg-5" v-if="model.status == -3 && model.statusComplain === 0">
          <button type="button" class="btn btn-primary w-100 done">Proses</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    
    <section v-if="state == 'product'">
      <a @click="state = 'detail'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Tambah Produk</a>

      <div class="row">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchProduct" :placeholder="'Cari ' +  (model.type == 1 ? 'Produk' : 'Bahan Baku')" v-model="productSearch">
            </div>
          </div>

          <div class="d-grid grid-product gap-3">
            <div v-for="product in products" :key="product.id"
              class="bg-white border-1 border-solid border-color-gray rounded-12 p-3">
              <div class="img-product center-content mb-3">
                <img :src="product.detailItemImage.length > 0 ? product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
              </div>
              <div class="text-xs text-medium text-center px-2 mb-2">{{ product.name }}</div>
              <div class="text-xs color-gray-60 text-center mb-2">Stok {{ currentStock( product.id ) | numFormat }}</div>
              <!-- <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.unitPrice | currency }}</div> -->
              <div class="d-flex justify-content-center align-items-center">
                <button @click="addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                </button>
                <input type="number" class="form-control input-number" :min="0" :max="currentStock( product.id )" :disabled="currentStock( product.id ) == 0"
                  v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                <button @click="addOrSubProduct(product, 'add')" :disabled="product.qty >= currentStock( product.id )"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                </button>
              </div>
            </div>
            <!--End List Product-->
          </div>
          <p class="text-center text-sm" v-if="products.length == 0">Tidak ada {{ model.type == 1 ? 'produk' : 'bahan baku' }}</p>
          <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
            <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
          </p>
        </div>
        <!--End List Product-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button justify-content-between text-sm color-gray-100 collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ selectedProducts.filter(x => x.qty > 0).length }} Terpilih</span>
                      <!-- <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPrice | currency }}</span> -->
                    </div>
                  </div>
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center py-3 px-4"
                  v-for="product in selectedProducts.filter(x => x.qty > 0)" :key="product.id">
                  <div>
                    <div class="text-xs text-medium mb-1">{{ product.name }}</div>
                    <!-- <div class="text-xs text-semiBold color-red-100">{{ product.unitPrice | currency }}</div> -->
                  </div>
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center">
                      <button @click="product.qty -= 1, addOrSubSelectedProduct(product, 'sub')" :disabled="product.qty == 0"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                      </button>
                      <input type="number" class="form-control input-number" :min="0" :max="currentStock( product.id )"
                        v-model.lazy="product.qty" @change="handleCustomQtySelectedProduct($event, product)" @keypress="numbersOnly">
                      <button @click="product.qty += 1, addOrSubSelectedProduct(product, 'add')" :disabled="product.qty >= currentStock( product.id )"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button type="button" @click="state = 'detail'" class="btn btn-primary w-100" :disabled="selectedProducts.length == 0">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>

    <div class="overlay-popup">
      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-3" v-if="[0].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Tolak Pesanan</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin untuk menolak pesanan ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn" @click="updateStatus(-2)">Ya</a>
          </div>
        </div>
      </div>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-if="[0].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Terima</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin menerima order ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="accept()">Ya</a>
          </div>
        </div>
      </div>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-if="[1].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Konfirmasi Pembayaran</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda sudah menerima pembayaran dari pemesan?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="updateStatus(2)">Ya</a>
          </div>
        </div>
      </div>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-if="[2].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Kirim Barang</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda sudah mengirim semua barang yang dipesan?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="updateStatus(3)">Ya</a>
          </div>
        </div>
      </div>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-if="[-3].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Proses</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin memproses komplain ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="updateStatusComplain(1)">Ya</a>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer
      :value="request"
      :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'detail',
      model: {
        requestBy: {},
        products: []
      },

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',
      selectedProducts: [],

      addPayment: '',
      addPaymentNote: null,

      isLoading: false,
    }
  },
  computed: {
    totalPrice: function() {
      let total = 0

      if ( this.model.status == 0 ) {
        this.selectedProducts.forEach(elm => {
          total += parseInt(elm.acceptedUnitPrice) * elm.qty
        });
      } else {
        this.model.products.forEach(elm => {
          total += parseInt(elm.acceptedUnitPrice) * elm.qty
        });
      }
      return total + this.model.addPayment
    },
    totalPembayaran: function() {
      let total = 0

      if ( this.model.status == 0 ) {
        this.selectedProducts.forEach(elm => {
          total += parseInt(elm.unitPrice) * elm.qty
        });
      } else {
        this.model.products.forEach(elm => {
          total += parseInt(elm.unitPrice) * elm.qty
        });
      }
      return total + this.model.addPayment
    },
    request: function() {
      let _ = this
      return {
        addPayment: _.addPayment ? parseInt(_.addPayment) : null,
        addPaymentNote: _.addPaymentNote,
        products: _.selectedProducts.filter( x => x.qty > 0 ).map( function( x ) {
          return {
            accuProductId: x.id,
            unitPrice: parseInt(x.unitPrice),
            qty: x.qty,
          }
        }),
        status: 1
      }
    },
    requestValidatePO: function() {
      let _ = this

      let payload = {
        type: _.model.type,
        kfsId: _.model.kfsId,
        acceptedKfsId: _.model.acceptedTo.kfsId
      }

      if ( _.model.type == 1 ) { // Produk
        payload.products = _.selectedProducts.map( function( x ) {
          return {
            accuProductId: x.id,
            qty: x.qty,
          }
        })
      } else if ( _.model.type == 2 ) { // Bahan Baku
        payload.raws = _.selectedProducts.map( function( x ) {
          return {
            accuRawMaterialId: x.id,
            qty: x.qty,
          }
        })
      } 

      return payload
    },
  },
  methods: {
    showOrder() {
      let _ = this

      _.axios.get('/purchase-orders/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data
          _.model.statusComplain = _.model.complaints.length > 0 ? _.model.complaints[0].status : null

          // if ( _.model.status == 0 ) {
          //   _.model.products = _.model.products.map( function(x) {
          //     return {
          //       ...x,
          //       qtyRequest: x.qty
          //     }
          //   })
          //   _.model.products.forEach(product => {
          //     if ( product.qtyRequest > _.currentStock(product.accuProductId) ) {
          //       product.qty = _.currentStock(product.accuProductId)
          //     }

          //     let searchProduct = _.products.map( x => x.id ).indexOf( product.accuProductId )
          //     if ( searchProduct != -1 ) {
          //       _.products[searchProduct].qtyRequest = product.qtyRequest
          //       _.products[searchProduct].qty = product.qty

          //       _.selectedProducts.push(JSON.parse(JSON.stringify(_.products[searchProduct])))
          //     }
          //   });
          // }

          _.getKfsInventory()
        })
        .catch( err => {
          console.log(err)
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    updateStatus( status ) {
      let _ = this

      _.axios.put('/purchase-orders/' + _.$route.params.id + '/status', { status: status })
        .then(() => {
          // _.showOrder()
          _.$router.back()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    updateStatusComplain( status ) {
      let _ = this

      _.axios.put('/purchase-orders/' + _.$route.params.id + '/status-complain', { status: status })
        .then(() => {
          _.showOrder()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getProduct( page ) {
      let _ = this
      _.axios.get('/misc/products'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.forEach(elm => {
              let qtyRequest = 0
              let qty = 0

              let searchProduct = _.selectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                qtyRequest = _.selectedProducts[searchProduct].qtyRequest
                qty = _.selectedProducts[searchProduct].qty
              }
              _.products.push({ 
                ...elm, 
                qtyRequest: qtyRequest, 
                qty: qty,
                // availableToSell: 5
               })
            });
          }

          _.showOrder()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    currentStock( accuProductId ) {
      let searchExistingProduct = this.products.map( x => x.id ).indexOf( accuProductId )
      if ( searchExistingProduct != -1 ) {
        return parseInt(this.products[searchExistingProduct].availableToSell)
      } else {
        return 0
      }
    },
    accept() {
      let _ = this

      _.axios.put('/purchase-orders/' + _.$route.params.id + '/accept', _.request)
        .then(() => {
          _.showOrder()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchProduct() {
      let _ = this
      _.products = []
      if ( _.model.type == 1 ) {
        _.getProduct(1)
      } else {

      }
    },
    addOrSubProduct( product, type ) {
      let _ = this

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        _.products[searchProduct].qty += 1
        if ( searchSelectedProduct != -1 ) {
          _.selectedProducts[searchSelectedProduct].qty += 1
        } else {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        // if ( _.selectedProducts[searchSelectedProduct].qty == 1 ) {
        //   _.selectedProducts.splice(searchSelectedProduct, 1)
        // } else {
          _.selectedProducts[searchSelectedProduct].qty -= 1
          _.products[searchProduct].qty -= 1
        // }
      }
    },
    addOrSubSelectedProduct( product, type ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchProduct != -1 ) {
          _.products[searchProduct].qty += 1
        } else {
          _.products.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        // if ( _.products[searchProduct].qty == 1 ) {
        //   let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
        //   _.selectedProducts.splice(searchSelectedProduct, 1)
        // }
        _.products[searchProduct].qty -= 1
      }
    },
    handleCustomQty( event, product ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      product = _.products[searchProduct]

      product.qty = parseInt( event.target.value )
      if ( _.currentStock( product.id ) < event.target.value ) {
        product.qty = parseInt( _.currentStock( product.id ) )
      }

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
      if ( searchSelectedProduct != -1 ) {
        _.selectedProducts[searchSelectedProduct].qty = product.qty
        // if (product.qty == 0) {
        //   _.selectedProducts.splice(searchSelectedProduct, 1)
        // }
      } else {
        if (product.qty > 0) {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      }
    },
    handleCustomQtySelectedProduct( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( _.currentStock( product.id ) < event.target.value ) {
        product.qty = parseInt( _.currentStock( product.id ) )
      }

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      if ( searchProduct != -1 ) {
        _.products[searchProduct].qty = product.qty
        // if (product.qty == 0) {
        //   let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
        //   _.selectedProducts.splice(searchSelectedProduct, 1)
        // }
      }
    },
    downloadInvoice() {
      let _ = this

      _.axios.get('/purchase-orders/' + _.$route.params.id  + '/invoice'
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'INVOICE-' + _.model.uniqueId + '.pdf'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getKfsInventory() {
      let _ = this

      let kfsId = null
      if ( _.model.acceptedBy ) {
        kfsId = _.model.acceptedBy.kfsId
      } else if ( _.model.acceptedTo ) {
        kfsId = _.model.acceptedTo.kfsId
      }

      if ( !kfsId ) {
        return;
      }

      _.axios.get('/kfs-inventory'
          + '?page=' + 1
          + '&limit=' + 99999
          + '&kfsId=' + kfsId
        )
        .then(resp => {
          const data = resp.data.data.rows
          
          _.products.forEach(product => {
            const searchInventory = data.map( x => x.accuId ).indexOf(product.id)
            if ( searchInventory != -1 ) {
              product.availableToSell = data[searchInventory].stock
              product.unitPrice = data[searchInventory].price
            } else {
              product.availableToSell = 0
            }
            _.$forceUpdate()
          });

          if ( _.model.status == 0 ) {
            _.model.products = _.model.products.map( function(x) {
              return {
                ...x,
                qtyRequest: x.qty
              }
            })
            _.model.products.forEach(product => {
              if ( product.qtyRequest > _.currentStock(product.accuProductId) ) {
                product.qty = _.currentStock(product.accuProductId)
              }

              let searchProduct = _.products.map( x => x.id ).indexOf( product.accuProductId )
              if ( searchProduct != -1 ) {
                _.products[searchProduct].qtyRequest = product.qtyRequest
                _.products[searchProduct].qty = product.qty

                _.selectedProducts.push(JSON.parse(JSON.stringify(_.products[searchProduct])))
              }
            });

            _.validatePO()
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    validatePO() {
      let _ = this

      _.isLoading = true
      _.axios.post('/purchase-orders/validation', _.requestValidatePO)
        .then( resp => {
          resp.data.data.products.forEach(product => {
            let indexSelectedProduct = null
            if (_.model.type == 1) {
              indexSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.accuProductId )
            } else {
              indexSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.accuRawMaterialId )
            }

            _.selectedProducts[indexSelectedProduct].unitPrice = parseInt(product.unitPrice)
            _.selectedProducts[indexSelectedProduct].acceptedUnitPrice = parseInt(product.acceptedUnitPrice)
            _.$forceUpdate()
            _.isLoading = false
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
          _.isLoading = false
        })
    },
  },
  mounted() {
    this.getProduct(1)
  },
  watch: {
    '$route.params.id': function(){
      _.products = []
      this.getProduct(1)
    },
    state: function() {
      if (this.state == 'detail' && this.model.id) {
        this.validatePO()
      }
    }
  }
}
</script>