<template>
  <div>
    <nav class="mt-4">
        <div class="nav nav-tabs" id="information-tab" role="tablist">
          <button class="nav-link active" id="bantuan-tab" data-bs-toggle="tab" data-bs-target="#bantuan" type="button"
            role="tab" aria-controls="bantuan" aria-selected="true">Personal</button>
          <button class="nav-link" id="tentang-kfs-tab" data-bs-toggle="tab" data-bs-target="#tentang-kfs" type="button"
            role="tab" aria-controls="tentang-kfs" aria-selected="false">Midtrans</button>
          <button class="nav-link" id="akun-bank-tab" data-bs-toggle="tab" data-bs-target="#akun-bank" type="button"
            role="tab" aria-controls="akun-bank" aria-selected="false">Akun Bank</button>
        </div>
      </nav>
      <div class="tab-content py-4">
        <div class="tab-pane fade show active" id="bantuan" role="tabpanel" aria-labelledby="bantuan-tab" tabindex="0">
          <ValidationObserver ref="personal"> 
            <form @submit.prevent="updatePersonal()">
              <div class="setting-content mt-4 col-xl-10">
                <div class="d-flex mb-4">
                  <div class="img-profile">
                    <img :src="modelPersonal.profile.photo ? modelPersonal.profile.photo : '/img/user-default.jpg'" class="img-fluid">
                  </div>
                  <div class="col ms-lg-4 ms-3">
                    <div class="text-md text-semiBold mb-0">{{ fullName }}</div>
                    <div class="text-sm color-gray-40 mb-2">Owner</div>
                    <ValidationProvider name="Foto" v-slot="{ errors }" ref="photo">
                      <div class="d-flex align-items-center flex-wrap">
                        <div class="upload-btn">
                          <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                          <input type="hidden" name="" v-model="modelPersonal.profile.photo">
                          <label class="btn btn-primary py-1 d-flex align-items-center" for="upload">
                            <img src="/img/icons/icn-cam.svg" class="img-fluid">
                            <span class="text-xs color-white ms-2 text-normal">Pilih Foto</span>
                          </label>
                        </div>
                        <div class="text-xs color-gray-40 ms-2 col-12 col-sm-auto mt-1 mt-sm-0">Ukuran file: max {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }})</div>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="text-lg text-semiBold mb-3">Informasi Pengguna</div>
                <div class="row gx-3 mb-3 mb-sm-4">
                  <div class="col-sm-6">
                    <ValidationProvider name="Nama pengguna" v-slot="{ errors }" rules="required">
                      <div class="mb-3">
                        <label class="text-sm color-gray-60 mb-2">Nama Pengguna</label>
                        <div class="position-relative">
                          <input type="text" class="form-control" name="" placeholder="" v-model="modelPersonal.profile.fullName">
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6">
                    <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email">
                      <div class="mb-3">
                        <label class="text-sm color-gray-60 mb-2">Email</label>
                        <div class="position-relative">
                          <input type="email" class="form-control" name="" placeholder="" v-model="modelPersonal.email">
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="text-lg text-semiBold mb-3">Ubah Password</div>
                <div class="row gx-3 mb-4">
                  <div class="col-sm-6">
                    <ValidationProvider name="Password lama" v-slot="{ errors }" ref="oldPassword">
                      <div class="mb-3">
                        <label class="color-gray-60 text-sm text-sm mb-2">Password Lama</label>
                        <div class="position-relative">
                          <input type="password" id="password" class="form-control" placeholder="" name="" v-model="modelPersonal.oldPassword" @keydown.space.prevent>
                          <span toggle="#password" class="btn-eye"></span>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6"> </div>
                  <div class="col-sm-6">
                    <ValidationProvider name="Password baru" v-slot="{ errors }" :rules="{required: (modelPersonal.oldPassword ? true : false), strong_password: true}" ref="newPassword">
                      <div class="mb-3">
                        <label class="color-gray-60 text-sm text-sm mb-2">Password Baru</label>
                        <div class="position-relative">
                          <input type="password" id="password1" class="form-control" placeholder="" name="" v-model="modelPersonal.newPassword" @keydown.space.prevent>
                          <span toggle="#password1" class="btn-eye"></span>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6">
                    <ValidationProvider name="Konfirmasi password baru" v-slot="{ errors }" :rules="{required: (modelPersonal.oldPassword ? true : false), confirmed: 'Password baru',  strong_password: true}">
                      <div class="mb-3">
                        <label class="color-gray-60 text-sm text-sm mb-2">Konfirmasi Password Baru</label>
                        <div class="position-relative">
                          <input type="password" id="password2" class="form-control" placeholder="" name="" v-model="modelPersonal.confPassword" @keydown.space.prevent>
                          <span toggle="#password2" class="btn-eye"></span>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="center-content">
                  <div class="col-12 col-sm-5">
                    <button class="btn btn-primary w-100" type="submit">Simpan</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" id="tentang-kfs" role="tabpanel" aria-labelledby="tentang-kfs-tab" tabindex="0">
          <ValidationObserver ref="midtrans"> 
            <form @submit.prevent="updateMidtrans()">
              <div class="setting-content mt-3 col-xl-10">
                <div class="text-lg text-semiBold mb-3">Konfigurasi Midtrans</div>
                <div class="row gx-3 mb-4">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="color-gray-60 text-sm text-sm mb-2">Merchant ID</label>
                      <div class="position-relative">
                        <input type="text" id="midtrans" class="form-control" placeholder="" name="" value="G2852645"
                          readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6"> </div>
                  <div class="col-sm-6">
                    <ValidationProvider name="Client key" v-slot="{ errors }" ref="clientKey" rules="required">
                      <div class="mb-3">
                        <label class="color-gray-60 text-sm text-sm mb-2">Client Key</label>
                        <div class="position-relative">
                          <input type="text" class="form-control" placeholder="" name="" v-model="midtrans.clientKey">
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6">
                    <ValidationProvider name="Server key" v-slot="{ errors }" ref="serverKey" rules="required">
                      <div class="mb-3">
                        <label class="color-gray-60 text-sm text-sm mb-2">Server Key</label>
                        <div class="position-relative">
                          <input type="text" class="form-control" placeholder="" name="" v-model="midtrans.serverKey">
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="center-content">
                  <div class="col-12 col-sm-5">
                    <button class="btn btn-primary w-100" type="submit">Simpan</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" id="akun-bank" role="tabpanel" aria-labelledby="akun-bank-tab" tabindex="0">
          <Bank/>
        </div>
      </div>

      <!-- <json-viewer
      :value="requestPersonal"
      :expand-depth="5"
      copyable
      boxed
      sort></json-viewer> -->
  </div>
</template>
<script>
import Bank from './bank/Index.vue'
export default {
  components: {
    Bank,
  },
  data() {
    return {
      maxFileSize: 5,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      fullName: null,
      modelPersonal: {
        email: null,
        profile: {
          fullName: null,
          photo: null,
        },
        oldPassword: null,
        newPassword: null
      },
      midtrans: {
        profileId: null,
        clientKey: null,
        serverKey: null,
      }
    }
  },
  computed: {
    requestPersonal: function() {
      if ( this.modelPersonal.oldPassword ) {
        return {
          fullName: this.modelPersonal.profile.fullName,
          email: this.modelPersonal.email,
          photo: this.modelPersonal.profile.photo,
          oldPassword: this.modelPersonal.oldPassword,
          newPassword: this.modelPersonal.newPassword
        }
      } else {
        return {
          fullName: this.modelPersonal.profile.fullName,
          email: this.modelPersonal.email,
          photo: this.modelPersonal.profile.photo
        }
      }
    }
  },
  methods: {
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]

      let isUploaded = await _.upload( file, _.allowedExtension, _.maxFileSize, 'photo' )
      if ( isUploaded ) {
        _.modelPersonal.profile.photo = isUploaded
      }
    },
    getPersonal() {
      let _ = this
      _.axios.get('/me')
        .then( resp => {
          _.modelPersonal = resp.data.message
          _.fullName = JSON.parse(JSON.stringify(_.modelPersonal.profile.fullName))

          this.midtrans = {
            profileId: _.modelPersonal.profile.id,
            clientKey: _.modelPersonal.profile.midtrans ? _.modelPersonal.profile.midtrans.clientKey : null,
            serverKey: _.modelPersonal.profile.midtrans ? _.modelPersonal.profile.midtrans.serverKey : null,
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    async updatePersonal() {
      let _ = this
      
      const isValid = await _.$refs.personal.validate()
      if ( !isValid ) return

      _.axios.put('/me/profile', _.requestPersonal)
        .then( resp => {
          if ( resp.data.success ) {
            _.fullName = resp.data.data.profile.fullName
            _.$root.$emit('updateProfile', resp.data.data)
            _.$notify({
              type: 'success',
              title: "Successfully update personal data"
            });
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    },
    async updateMidtrans() {
      let _ = this
      
      const isValid = await _.$refs.midtrans.validate()
      if ( !isValid ) return

      _.axios.post('/me/set-midtrans', _.midtrans)
        .then( resp => {
          if ( resp.data.success ) {
            _.$notify({
              type: 'success',
              title: "Successfully update midtrans data"
            });
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    }
  },
  mounted() {
    this.getPersonal()
  },
}
</script>