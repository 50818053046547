<template>
  <div>
    <div class="bg-white shadow rounded-12 p-3">
      <div class="row gx-2 justify-content-between mb-3">
        <div class="col-12">
          <div class="position-relative">
            <div class="form-search">
              <input type="text" class="form-control" name="" id="" placeholder="Cari kata kunci" v-model="search">
            </div>
          </div>
        </div>
        <!-- <div class="col-5 col-lg-2 col-sm-4">
          <a href="booking-treatment.html" class="btn btn-primary w-100">Buat Baru</a>
        </div> -->
      </div>
      <Table :table="table"/>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'
export default {
	components: {
    Table
	},
  data() {
    return {
      table: {
        options: {
          columns: [
            { name: 'Nomor Booking', sortable: true, reference: 'noBooking' },
            { name: 'Tanggal Booking', sortable: true, reference: 'startDate' },
            { name: 'Treatment', sortable: false, reference: 'treatments' },
            { name: 'Staff', sortable: false, reference: 'staff' },
            { name: 'Lokasi', sortable: false, reference: 'since' },
            { name: 'Status DP', sortable: true, reference: 'statusDownpayment' },
          ],
          rows: [
            { reference: 'noBooking', type: 'text' },
            { reference: 'startDate', type: 'date' },
            { reference: 'treatments', type: 'text' },
            { reference: 'staff', type: 'text' },
            { reference: 'since', type: 'text' },
            { reference: 'statusDownpayment', type: 'statusDownpayment' },
          ],
        },
        data: []
      },
      page: 1,
      limit: 10,
      sortby: 'id.desc',
      search: '',
      selected: []
    }
  },
  methods: {
    get() {
      let _ = this
      _.axios.get('/customers/'+ _.$route.params.id +'/bookings'
        + '?page=' + _.page
        + '&limit=' + _.limit
        + '&sortBy=' + _.sortby
        + '&search=' + _.search
      )
      .then( resp => {
        _.table.data = resp.data.data
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    }
  },
  mounted() {
    let _ = this
    _.get()

    _.$root.$on("changePage", (data) => {
      _.page = data
    });
    _.$root.$on("changeLimit", (data) => {
      _.limit = data
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.sortby = 'id.desc'
      } else {
        _.sortby = data
      }
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  },
  watch: {
    page: function(){
      this.get()
    },
    limit: function(){
      this.get()
    },
    sortby: function(){
      this.get()
    },
    search: function() {
      this.get()
    }
  }
}
</script>