<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center">Selamat Datang</h6>
    <p class="text-center text-sm color-gray-60 mb-4">Masukkan alamat email yang sudah terdaftar</p>
    <ValidationObserver ref="observer">
      <form @submit.prevent="login()">
        <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
          <div class="form-group auth mb-4">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Email</label>
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="" name="" value="" v-model="model.email">
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider name="Password" v-slot="{ errors }" rules="required" ref="password">
          <div class="form-group auth mb-3">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Password</label>
            <div class="position-relative">
              <input type="password" id="password" class="form-control" placeholder="" name="" value="" v-model="model.password">
              <span toggle="#password" class="btn-eye"></span>
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <router-link to="/forgot-password" class="text-sm d-block text-right text-medium mb-4">Lupa Password?</router-link>
        <button type="submit" class="btn btn-primary w-100">Masuk</button>
      </form>
    </ValidationObserver>

    <p class="text-center mb-0 mt-1"> <small class="text-danger text-sm text-xs">{{errorMsg}}</small></p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        email: null,
        password: null,
      },
      errorMsg: null
    }
  },
  methods: {
    async login() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return
      
      _.axios.post('/auth/login', { ..._.model, email: _.model.email.toLowerCase() })
        .then( resp => {
          if ( resp.data.data.roleId == 2 ) {
            _.$store.dispatch('login', resp.data.data)
            window.location = '/'
          } else {
            _.errorMsg = 'Maaf, Anda tidak memiliki akses'
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.errorMsg = err.response.data.message
          }
        })
    }
  },
}
</script>