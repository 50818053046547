<template>
  <div>
    <div class="mt-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="text-base text-semiBold">Promo Berjalan</div>
        <a href="#" class="text-sm text-medium cancel">Riwayat</a>
      </div>

      <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="promo-1" data-bs-toggle="tab" data-bs-target="#promo-1-pane" @click="promoStatus = null"
            type="button" role="tab" aria-controls="promo-1-pane" aria-selected="true">Semua</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="promo-4-tab" data-bs-toggle="tab" data-bs-target="#promo-4-tab-pane" @click="promoStatus = 'active'"
            type="button" role="tab" aria-controls="promo-4-tab-pane" aria-selected="false">Aktif</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="promo-5-tab" data-bs-toggle="tab" data-bs-target="#promo-5-tab-pane" @click="promoStatus = 'done'"
            type="button" role="tab" aria-controls="promo-5-tab-pane" aria-selected="false">Selesai</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="promo-1-pane" role="tabpanel" aria-labelledby="promo-1-tab"></div>
        <div class="tab-pane fade" id="promo-4-tab-pane" role="tabpanel" aria-labelledby="promo-4-tab" tabindex="0"></div>
        <div class="tab-pane fade" id="promo-5-tab-pane" role="tabpanel" aria-labelledby="promo-5-tab" tabindex="0"></div>
      </div>

      <div class="row gx-3" v-if="promos.length > 0">
        <CardPromo v-for="promo in promos" :key="promo.id" :data="promo"/>
      </div>
      <p class="text-center text-sm" v-if="promos.length == 0">Tidak ada promo</p>
      <p class="text-center text-sm" v-if="promos.length > 0 && promoCurrentPage < promoTotalPage">
        <a class="color-gray-100" @click="getPromo( promoCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
      </p>
    </div>

    <div class="overlay-popup">
      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold text-base">Pilih KFS</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <a @click="kfsId = kfs.id" class="d-flex align-items-center p-3 close-popup border-1 border-solid rounded-10 border-color-gray mb-3" v-for="kfs in kfses" :key="kfs.id">
            <img :src="kfs.photo ? kfs.photo : '/img/place.jpg'" class="treatment rounded-2">
            <div class="col ps-2 text-xs color-gray-100 text-medium">{{ kfs.name }}, {{ kfs.since }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardPromo from './components/CardPromo.vue'
export default {
  components: {
    CardPromo
  },
  data() {
    return {
      kfsId: null,

      promos: [],
      promoCurrentPage: 1,
      promoTotalPage: 1,
      promoLimit: 10,
      promoSortby: 'id.desc',
      promoSearch: '',
      promoStatus: null,

      kfses: [],
      kfsCurrentPage: 1,
      kfsTotalPage: 1,
      kfsLimit: 10,
      kfsSortby: 'id.desc',
      kfsSearch: '',
    }
  },
  methods: {
    getPromo( page ) {
      let _ = this

      _.axios.get('/me/promotions'
          + '?page=' + page
          + '&limit=' + _.promoLimit
          + '&sortBy=' + _.promoSortby
          + (_.promoStatus ? '&status=' + _.promoStatus : '' )
          + (_.kfsId ? '&kfsId=' + _.kfsId : '' )
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.promoCurrentPage = data.currentPage
            _.promoTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.promos.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getKfs( page ) {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + page
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
          + '&search=' + _.kfsSearch
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsCurrentPage = data.currentPage
          _.kfsTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.kfses.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getPromo(1)
    this.getKfs(1)
  },
  watch: {
    promoStatus: function() {
      this.promos = []
      this.getPromo(1)
    },
    kfsId: function() {
      this.promos = []
      this.getPromo(1)
    }
  }
}
</script>