<template>
  <div class="col-xl-4 col-lg-6">
    <div class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-xs color-gray-60">{{ data.updatedAt | moment('DD MMM YYYY') }}</div>
        <div class="status bg-lightOrange color-darkOrange text-xs text-medium" v-if="data.requestedStatus == 0">Pengajuan</div>
        <div class="status bg-red-10 color-red-100 text-xs text-medium" v-else-if="data.requestedStatus == -1">Ditolak</div>
        <div class="status bg-lightTosca color-darkTosca text-xs text-medium" v-else>Disetujui</div>
      </div>
      <hr class="dashed">
      <div class="d-flex align-items-center">
        <img :src="data.collectionImage && data.collectionImage.length > 0 ? data.collectionImage[0] : '/img/user-3.jpg'" class="treatment rounded-2">
        <div class="col ps-2">
          <div class="text-sm text-medium text-hidden">{{ data.name }}</div>
          <ul class="item-info w-a mb-0" v-if="data.requestedStatus == 1">
            <li><span class="text-xs text-semiBold">{{ data.oldPrice | currency }}</span></li>
            <li><span class="text-xs color-red-100 text-semiBold">{{ data.requestedPrice | currency }}</span></li>
          </ul>
          <ul class="item-info w-a mb-0" v-else>
            <li><span class="text-xs text-semiBold">{{ data.price | currency }}</span></li>
            <li><span class="text-xs color-red-100 text-semiBold">{{ data.requestedPrice | currency }}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>