import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/helpers/helper'

import VuePageTransition from 'vue-page-transition'
import store from './store'
Vue.use(VuePageTransition)

import axios from './plugins/axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import validate from './plugins/vee-validate.js'
import { ValidationObserver } from 'vee-validate'
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)

import Notifications from 'vue-notification'
Vue.use(Notifications)

const moment = require('moment')
require('moment/locale/id')
Vue.use(require('vue-moment'), {moment})

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker);

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import { DatePicker } from 'vue-drumroll-datetime-picker'
import 'vue-drumroll-datetime-picker/dist/style.css'
Vue.component('DatePickerDrum', DatePicker)

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import firebase from "firebase/app";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: 'AIzaSyD11zKf4emvDXDd2C0eM1_cVUg_oYWEz_M',
  authDomain: 'kezia-skincare.firebaseapp.com',
  projectId: 'kezia-skincare',
  storageBucket: 'kezia-skincare.appspot.com',
  messagingSenderId: '729557066537',
  appId: '1:729557066537:web:97d12174e2257c8e488d38',
  measurementId: 'G-TV4XRF5P0R',
})

navigator.serviceWorker
  .register('/firebase-messaging-sw.js')
  .then(registration => {
    console.log("[Firebase] Registration Successfull");
    console.log("[Firebase] Registration Scope:", registration.scope);
  })
  .catch(err => {
    console.log(err)
  })

import Pusher from 'pusher-js' 
window.Pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: false
});

Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
