<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
        class="icon-ico-back me-2"></span>
      Detail Promo</a>

    <div class="row ">
      <div class="col-lg-7 mb-4">
        <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
          <div class="d-flex align-items-center">
            <div class="bullet" :class="'bg-' + promoStatusColor(model.statusText)"></div>
            <div class="text-semiBold text-hidden text-xs ms-3" :class="'color-' + promoStatusColor(model.statusText)">{{ promoStatusText(model.statusText) }}</div>
          </div>
        </div>

        <div class="bg-white rounded-12 p-3 mb-3">
          <div class="text-sm mb-2 text-medium color-gray-100">Diskon {{ promoTypeText( model.promoType ) }}</div>
          <hr class="dashed">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="col-6 text-xs color-gray-80 text-hidden">Target</div>
            <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.allTargets ? 'Semua Pelanggan KFS' : model.kfs.name }}</div>
            <!-- <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-else>{{ model.promotionTargets.map( x => x.kfsId ).join(", ") }}</div> -->
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="col-6 text-xs color-gray-80 text-hidden">Durasi</div>
            <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100"> {{ model.startDate | moment('DD/MM/YYYY') }} - {{ model.endDate | moment('DD/MM/YYYY') }}</div>
          </div>
          <section v-if="model.promoType == 1"><!-- Nominal -->
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="col-6 text-xs color-gray-80 text-hidden">Nominal Diskon (Rp)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPrice | currency }}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="col-6 text-xs color-gray-80 text-hidden">Minimal Pembelian (Rp)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.minimumPayment | currency }}</div>
            </div>
          </section>
          <section v-if="model.promoType == 2"><!-- Persentase -->
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="col-6 text-xs color-gray-80 text-hidden">Persentase Diskon (%)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPercentage }}%</div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="col-6 text-xs color-gray-80 text-hidden">Maks. Diskon (Rp)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPercentageMax | currency }}</div>
            </div>
          </section>
          <section v-if="model.promoType == 3"><!-- Produk -->
            <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.discountPercentage">
              <div class="col-6 text-xs color-gray-80 text-hidden">Persentase Diskon (%)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPercentage }}%</div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.discountPrice">
              <div class="col-6 text-xs color-gray-80 text-hidden">Harga Setelah Diskon (Rp)</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-if="model.products.length > 0">{{ model.products[0].product.unitPrice - model.discountPrice | currency }}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="col-6 text-xs color-gray-80 text-hidden">Jumlah Produk</div>
              <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.productCount | numFormat }}</div>
            </div>
          </section>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="col-6 text-xs color-gray-80 text-hidden">Kuota (pengguna)</div>
            <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-if="model.quota > 0">{{ model.quota | numFormat }}</div>
            <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-else>-</div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-6 text-xs color-gray-80 text-hidden">Dibuat Oleh</div>
            <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.createdByDetail ? model.createdByDetail.roleName : 'Pusat'}}</div>
          </div>

          <section v-if="model.promoType == 3">
            <hr class="dashed">
            <div class="text-xs mb-3 color-gray-60">{{ model.products.length }} Produk Terpilih</div>
            <div class="d-flex justify-content-between align-items-center mb-3" v-for="product in model.products" :key="product.id">
              <div class="col-6 text-xs text-medium color-gray-100 text-hidden">{{ product.product.name }}</div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <div class="text-xs mx-2 color-red-100 text-semiBold">{{ generateDiscount(product) | currency }}</div>
                <div class="text-xxs color-gray-60 text-decoration-line-through">{{ product.product.unitPrice | currency }}</div>
              </div>
            </div>
          </section>
        </div>

        <div class="bg-white rounded-12 p-3 mb-3" v-if="model.statusText == 'aktif'">
          <div class="text-sm text-medium color-gray-100 mb-3">Pengguna Promo </div>
          <hr class="dashed">
          <div class=" d-flex justify-content-between align-items-center ">
            <div class="text-xs color-gray-80 text-hidden">Total Pengguna </div>
            <div class="text-xs text-medium color-gray-100 text-hidden">{{ transactionTotal | numFormat }}</div>
          </div>
          <hr class="dashed">
          <div class="text-xs text-medium color-gray-100 mb-3">Daftar Transaksi </div>

          <div class=" d-flex justify-content-between align-items-center mb-3"
            v-for="transaction in transactions" :key="transaction.id">
            <div class="text-xs color-gray-80 text-hidden">{{ transaction.createdAt | moment('DD/MM/YYYY') }}</div>
            <a href="#" class="text-xs text-medium color-red-100 text-hidden">{{ transaction.noOrder }}</a>
          </div>

          <div class=" d-flex justify-content-center align-items-center ">
            <p class="text-center text-xs" v-if="transactions.length == 0">Tidak ada transaksi</p>
            <p class="text-center text-xs" v-if="transactions.length > 0 && transactionCurrentPage < transactionTotalPage">
              <a class="color-gray-100" @click="getTransaction( transactionCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
            </p>
          </div>
        </div>
      </div>
      <!--End List Product-->

      <div class="col-lg-5">
        <div class="text-sm text-semiBold color-gray-100 mb-3">Kode Promo </div>
        <div class="bg-white rounded-12 p-3 mb-3">
          <div class=" d-flex justify-content-between align-items-center ">
            <div class="text-sm color-red-100 text-medium text-italic text-hidden" style="padding-right:10px;">{{ model.voucherCode }} </div>
            <button class="btn btn-copy" type="button" @click="copy(model.voucherCode)"><span class="icon-ico-copy"></span></button>
          </div>
        </div>
        <button class="btn btn-primary w-100 cancel" v-if="model.statusText == 'pengajuan'">Batalkan</button>
      </div>
      <!--End Right Side Bar-->
    </div>

    <div class="overlay-popup">
      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Batalkan Promo</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin membatalkan promo ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn" @click="cancel()">Ya</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        promotionTargets: [],
        products: [],
        kfs: {}
      },
      transactions: [],
      transactionCurrentPage: 1,
      transactionTotalPage: 1,
      transactionLimit: 10,
      transactionSortby: 'id.desc',
      transactionTotal: 0,
    }
  },
  methods: {
    showPromo() {
      let _ = this

      _.axios.get('/kfs-promotion/' + _.$route.params.promoId)
        .then( resp => {
          _.model = resp.data.data

          if (_.model.statusText == 'aktif') {
            this.getTransaction(1)
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    generateDiscount( product ) {
      if ( parseInt(product.product.unitPrice) <= 0 ) return 0
      
      let totalDiscount = 0
      let finalResult = 0

      if ( this.model.discountPercentage ) {
        totalDiscount = parseFloat(product.product.unitPrice) * this.model.discountPercentage / 100 
        if ( this.model.discountPercentageMax > 0 && totalDiscount > this.model.discountPercentageMax ) {
          finalResult = parseFloat(product.product.unitPrice) - this.model.discountPercentageMax 
        } else {
          finalResult = parseFloat(product.product.unitPrice) - totalDiscount
        }
      } else {
        finalResult = parseFloat(product.product.unitPrice) - this.model.discountPrice
      }

      return finalResult >= 0 ? finalResult : 0
    },
    cancel() {
      let _ = this

      _.axios.put('/kfs-promotion/' + _.$route.params.promoId + '/status', { isCanceled: true })
        .then(() => {
          _.showPromo()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getTransaction( page ) {
      let _ = this

      _.axios.get('/kfs-promotion/' + _.$route.params.id + '/transactions'
          + '?page=' + page
          + '&limit=' + _.transactionLimit
          + '&sortBy=' + _.transactionSortby
        )
        .then( resp => {
          const data = resp.data.data
          _.transactionTotal = data.count
          _.transactionCurrentPage = data.currentPage
          _.transactionTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.transactions.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.showPromo()
  },
}
</script>