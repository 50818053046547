<template>
  <div class="col-xl-4 col-lg-6">
    <router-link :to="'/kfs/' + $route.params.id + '/promo/' + data.id" class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center col-5">
          <div class="bullet" :class="'bg-' + promoStatusColor(data.statusText)"></div>
          <div class="text-semiBold text-hidden text-xs ms-3" :class="'color-' + promoStatusColor(data.statusText)">{{ promoStatusText(data.statusText) }}</div>
        </div>
        <div class="col-7 text-hidden text-xs color-gray-60 text-right">Promo ID #{{ data.id }}</div>
      </div>
      <hr class="dashed">
      
      <div class="text-xs mb-2 text-medium color-gray-100">Diskon {{ promoTypeText( data.promoType ) }}</div>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="col-6 text-xs color-gray-80 text-hidden">Durasi</div>
        <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.startDate | moment('DD/MM/YYYY') }} - {{ data.endDate | moment('DD/MM/YYYY') }}</div>
      </div>
      <section v-if="data.promoType == 1"><!-- Nominal -->
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="col-6 text-xs color-gray-80 text-hidden">Nominal Diskon (Rp)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.discountPrice | currency }}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-6 text-xs color-gray-80 text-hidden">Minimal Pembelian (Rp)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.minimumPayment | currency }}</div>
        </div>
      </section>
      <section v-if="data.promoType == 2"><!-- Persentase -->
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="col-6 text-xs color-gray-80 text-hidden">Persentase Diskon (%)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.discountPercentage }}%</div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-6 text-xs color-gray-80 text-hidden">Maks. Diskon (Rp)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.discountPercentageMax | currency }}</div>
        </div>
      </section>
      <section v-if="data.promoType == 3"><!-- Produk -->
        <div class="d-flex justify-content-between align-items-center mb-2" v-if="data.discountPercentage">
          <div class="col-6 text-xs color-gray-80 text-hidden">Persentase Diskon (%)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.discountPercentage }}%</div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2" v-if="data.discountPrice">
          <div class="col-6 text-xs color-gray-80 text-hidden">Nominal Diskon (Rp)</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.discountPrice | currency }}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-6 text-xs color-gray-80 text-hidden">Jumlah Produk</div>
          <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ data.productCount | numFormat }}</div>
        </div>
      </section>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>