<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
        class="icon-ico-back me-2"></span>
      Detail KFS</a>

    <nav>
      <div class="nav nav-tabs " id="information-tab" role="tablist">
        <button class="nav-link" :class="state == 'employee' ? 'active' : ''" id="bantuan-tab" data-bs-toggle="tab" data-bs-target="#bantuan" type="button"
          role="tab" aria-controls="bantuan" aria-selected="true"
          @click="state = 'employee'">Karyawan {{ counter.employee }}</button>
        <button class="nav-link" :class="state == 'treatment' ? 'active' : ''" id="tentang-kfs-tab" data-bs-toggle="tab" data-bs-target="#tentang-kfs" type="button"
          role="tab" aria-controls="tentang-kfs" aria-selected="false"
          @click="state = 'treatment'">Treatment {{ counter.treatment }}</button>
        <button class="nav-link" :class="state == 'produk' ? 'active' : ''" id="syarat-tab" data-bs-toggle="tab" data-bs-target="#syarat" type="button"
          role="tab" aria-controls="syarat" aria-selected="false"
          @click="state = 'produk'">Produk {{ counter.product }}</button>
        <button class="nav-link " :class="state == 'promo' ? 'active' : ''" id="komplain-tab" data-bs-toggle="tab" data-bs-target="#komplain" type="button"
          role="tab" aria-controls="komplain" aria-selected="false"
          @click="state = 'promo'">Promo {{ counter.promo }}</button>
        <button class="nav-link " :class="state == 'info' ? 'active' : ''" id="batal-tab" data-bs-toggle="tab" data-bs-target="#batal" type="button" role="tab"
          aria-controls="batal" aria-selected="false"
          @click="state = 'info'">Info</button>
        <button class="nav-link " :class="state == 'old' ? 'active' : ''" id="old-tab" data-bs-toggle="tab" data-bs-target="#old" type="button" role="tab"
          aria-controls="old" aria-selected="false"
          @click="state = 'old'">Old Transaction</button>
      </div>
    </nav>
    <div class="tab-content pt-4">
      <div class="tab-pane fade" :class="state == 'employee' ? 'show active' : ''" id="bantuan" role="tabpanel" aria-labelledby="bantuan-tab" tabindex="0">
        <Employee v-if="state == 'employee'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'treatment' ? 'show active' : ''" id="tentang-kfs" role="tabpanel" aria-labelledby="tentang-kfs-tab" tabindex="0">
        <Treatment v-if="state == 'treatment'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'produk' ? 'show active' : ''" id="syarat" role="tabpanel" aria-labelledby="syarat-tab" tabindex="0">
        <Product v-if="state == 'produk'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'promo' ? 'show active' : ''" id="komplain" role="tabpanel" aria-labelledby="komplain-tab" tabindex="0">
        <Promo v-if="state == 'promo'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'old' ? 'show active' : ''" id="old" role="tabpanel" aria-labelledby="old-tab" tabindex="0">
        <OldTransaction v-if="state == 'old'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'info' ? 'show active' : ''" id="batal" role="tabpanel" aria-labelledby="batal-tab" tabindex="0">
        <Info v-if="state == 'info'"/>
      </div>
    </div>
  </div>
</template>
<script>
import Employee from './employee/Index.vue'
import Treatment from './treatment/Index.vue'
import Product from './Product.vue'
import Promo from './promo/Index.vue'
import Info from './Info.vue'
import OldTransaction from './OldTransaction.vue'

export default {
  components: {
    Employee,
    Treatment,
    Product,
    Promo,
    Info,
    OldTransaction,
  },
  data() {
    return {
      state: 'employee',
      counter: {
        employee: 0,
        treatment: 0,
        product: 0,
        promo: 0,
      }
    }
  },
  methods: {
    getCounter() {
      let _ = this
      _.axios.get('/kfs/badge'
          + '?kfsId=' + _.$route.params.id
        )
        .then( resp => {
          _.counter = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getCounter()
  },
}
</script>