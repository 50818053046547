<template>
  <div class="col-xl-4 col-lg-6">
    <div class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="col-auto d-flex align-items-center">
          <img :src="data.profile.photo ? data.profile.photo : '/img/user-default.jpg'" class="karyawan">
          <div class="text-sm text-medium text-hidden ps-2">{{ data.profile.fullName}}</div>
        </div>
        <div class="status text-xs text-medium" 
          :class="'bg-light' + employeeRoleColor(data.roleId) + ' color-dark' + employeeRoleColor(data.roleId)">{{ employeeRoleText(data.roleId )}}</div>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <div class="col-8">
          <div class="text-xs color-gray-40 text-hidden">{{ data.email }}</div>
        </div>
        <div class="col-auto">
          <div class="d-flex align-items-center">
            <a class="text-md d-block cancel mx-2" @click="showDetailEmployee()"><span class="icon-ico-eye"></span></a>
            <router-link :to="'/kfs/' + $route.params.id + '/employee/' + data.id + '/edit'" class="text-md color-gray-100 me-1"><span class="icon-ico-edit"></span></router-link>
            <a class="done" style="width: 18px; height: 18px;" @click="showDetailEmployee()"><img src="/img/icons/delete.svg" class="img-fluid" style="vertical-align: baseline; margin-top: -1px;"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  methods: {
    showDetailEmployee() {
      this.$root.$emit('setEmployeeDetail', this.data)
    }
  },
}
</script>