<template>
  <div id="detail-treatment" class="box-xs hidden bg-white rounded-4 p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-semiBold">Detail Treatment</div>
      <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
    </div>
    <div class="content-popup">
      <div class="d-flex justify-content-between ">
        <div class="col d-flex align-items-center">
          <img :src="data.collectionImage && data.collectionImage.length > 0 ? data.collectionImage[0] : '/img/user-3.jpg'" class="treatment rounded-2">
          <div class="col ps-2">
            <div class="text-sm text-medium text-hidden">{{ data.name }}</div>
            <ul class="item-info mb-0">
              <li><span class="text-xs text-medium">{{ data.durationHours }} Jam {{ data.durationMinutes }} Menit</span></li>
              <li><span class="text-xs color-red-100 text-semiBold">{{ data.price | currency }}</span></li>
            </ul>
          </div>
        </div>
      </div>
      <hr>
      <!-- <div class="d-flex justify-content-between align-items-center mb-2" v-for="product in data.products" :key="product.id">
        <div class="text-xs color-gray-80">{{ product.name }}</div>
        <div class="text-xs text-medium">{{ product.stock }} ml</div>
      </div> -->
      <div v-if="data.collectionRawMaterial">
        <div class="d-flex justify-content-between align-items-center mb-2" v-for="rawMaterial in data.collectionRawMaterial" :key="rawMaterial.id">
          <div class="text-xs color-gray-80">{{ rawMaterial.material ? rawMaterial.material.name : '-' }}</div>
          <div class="text-xs text-medium">{{ rawMaterial.unitValue | numFormat }} {{ rawMaterial.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data']
}
</script>