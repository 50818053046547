<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-sm text-medium">Karyawan Terdaftar</div>
      <div class="d-flex align-items-center">
        <span class="text-sm text-medium me-2">Tambah Karyawan</span>
        <router-link :to="'/kfs/' + $route.params.id + '/employee/add'" class="btn-add">+</router-link>
      </div>
    </div>
    <ul class="nav nav-tabs bemployee-0 mb-3 border-0" id="tabTop" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="all" data-bs-toggle="tab" data-bs-target="#all-pane" type="button"
          role="tab" aria-controls="all-pane" aria-selected="true"
          @click="employeeRole = null">Semua</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="waiting-tab" data-bs-toggle="tab" data-bs-target="#waiting-tab-pane"
          type="button" role="tab" aria-controls="waiting-tab-pane" aria-selected="false"
          @click="employeeRole = 4">Terapis</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="layanan-tab" data-bs-toggle="tab" data-bs-target="#layanan-tab-pane"
          type="button" role="tab" aria-controls="layanan-tab-pane" aria-selected="false"
          @click="employeeRole = 3">Kasir</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="all-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0"></div>
      <div class="tab-pane fade" id="waiting-tab-pane" role="tabpanel" aria-labelledby="waiting-tab" tabindex="0"></div>
      <div class="tab-pane fade" id="layanan-tab-pane" role="tabpanel" aria-labelledby="layanan-tab" tabindex="0"></div>
    </div>

    <div class="row gx-3" v-if="employees.length > 0">
      <Card v-for="employee in employees" :key="employee.id" :data="employee"/>
    </div>
    <p class="text-center text-sm" v-if="employees.length == 0">Tidak ada karyawan</p>
    <p class="text-center text-sm" v-if="employees.length > 0 && employeeCurrentPage < employeeTotalPage">
      <a class="color-gray-100" @click="getEmployee( employeeCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>

    <div class="overlay-popup ">
      <Modal :data="employeeDetail" v-if="employeeDetail"/>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Hapus karyawan?</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apakah Anda yakin ingin menghapus karyawan ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <button class="btn btn-border-primary w-100" type="button" @click="deleteKaryawan()">Ya</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from './Card.vue'
import Modal from './Modal.vue'

export default {
  components: {
    Card,
    Modal
  },
  data() {
    return {
      employees: [],
      employeeCurrentPage: 1,
      employeeTotalPage: 1,
      employeeLimit: 10,
      employeeSortby: 'id.desc',
      employeeRole: null,
      employeeDetail: null
    }
  },
  methods: {
    getEmployee( page ) {
      let _ = this

      _.axios.get('/kfs-employee'
          + '?kfsId=' + _.$route.params.id
          + '&page=' + page
          + '&limit=' + _.employeeLimit
          + '&sortBy=' + _.employeeSortby
          + (_.employeeRole ? '&roleId=' + _.employeeRole : '')
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.employeeCurrentPage = data.currentPage
            _.employeeTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.employees.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    deleteKaryawan() {
      let _ = this
      _.axios.delete('/employees/' + _.employeeDetail.id)
        .then( resp => {
          if ( resp.data.success ) {
            $('.close-popup').click()
            _.employees = []
            _.getEmployee(1)
            _.$notify({
              type: 'success',
              title: 'Successfully delete employee',
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    let _ = this
    
    _.getEmployee(1)
    _.$root.$on('setEmployeeDetail', (data) => {
      _.employeeDetail = data
    })
  },
  watch: {
    employeeRole: function() {
      this.employees = []
      this.getEmployee(1)
    }
  }
}
</script>