<template>
  <div>
    <div class="add-promo">
      <div class="d-flex align-items-center justify-content-end">
        <span class="text-sm text-medium me-2">Buat Promo</span>
        <a class="btn-add new-promo">+</a>
      </div>
    </div>
    <ul class="nav nav-tabs bpromo-0 mb-3 border-0" id="tabTop" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="promo-1" data-bs-toggle="tab" data-bs-target="#promo-1-pane"
          type="button" role="tab" aria-controls="promo-1-pane" aria-selected="true" @click="promoStatus = ''">Semua</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-2-tab" data-bs-toggle="tab" data-bs-target="#promo-2-tab-pane"
          type="button" role="tab" aria-controls="promo-2-tab-pane" aria-selected="false" @click="promoStatus = 'submission'">Pengajuan</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-3-tab" data-bs-toggle="tab" data-bs-target="#promo-3-tab-pane"
          type="button" role="tab" aria-controls="promo-3-tab-pane" aria-selected="false" @click="promoStatus = 'receive'">Diterima</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-4-tab" data-bs-toggle="tab" data-bs-target="#promo-4-tab-pane"
          type="button" role="tab" aria-controls="promo-4-tab-pane" aria-selected="false" @click="promoStatus = 'active'">Aktif</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-5-tab" data-bs-toggle="tab" data-bs-target="#promo-5-tab-pane"
          type="button" role="tab" aria-controls="promo-5-tab-pane" aria-selected="false" @click="promoStatus = 'done'">Selesai</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-6-tab" data-bs-toggle="tab" data-bs-target="#promo-6-tab-pane"
          type="button" role="tab" aria-controls="promo-6-tab-pane" aria-selected="false" @click="promoStatus = 'reject'">Ditolak</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="promo-7-tab" data-bs-toggle="tab" data-bs-target="#promo-7-tab-pane"
          type="button" role="tab" aria-controls="promo-7-tab-pane" aria-selected="false" @click="promoStatus = 'cancel'">Dibatalkan</button>
      </li>
    </ul>

    <div class="row gx-3" v-if="promos.length > 0">
      <CardPromo v-for="promo in promos" :key="promo.id" :data="promo"/>
    </div>
    <p class="text-center text-sm" v-if="promos.length == 0">Tidak ada promo</p>
    <p class="text-center text-sm" v-if="promos.length > 0 && promoCurrentPage < promoTotalPage">
      <a class="color-gray-100" @click="getPromo( promoCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>

    <div class="overlay-popup">
      <div id="new-promo" class="box-xs hidden bg-white rounded-4 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Buat Promo Baru</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <router-link :to="'/kfs/' + $route.params.id + '/promo/add?promoType=2'"
            class="rounded-12 d-block border-1 hover-gray border-solid border-color-gray py-2 px-3 bg-white mb-3">
            <div class="text-xs text-medium color-gray-100 mb-1">Diskon Persentase</div>
            <div class="text-xs color-gray-60">Beri diskon dalam persen yang Anda tentukan</div>
          </router-link>
          <router-link :to="'/kfs/' + $route.params.id + '/promo/add?promoType=3'"
            class="rounded-12 d-block border-1 hover-gray border-solid border-color-gray py-2 px-3 bg-white mb-3">
            <div class="text-xs text-medium color-gray-100 mb-1">Diskon Produk</div>
            <div class="text-xs color-gray-60">Beri diskon (harga coret) pada produk tertentu</div>
          </router-link>
          <router-link :to="'/kfs/' + $route.params.id + '/promo/add?promoType=1'"
            class="rounded-12 d-block border-1 hover-gray border-solid border-color-gray py-2 px-3 bg-white mb-3">
            <div class="text-xs text-medium color-gray-100 mb-1">Diskon Nominal</div>
            <div class="text-xs color-gray-60">Beri diskon dalam nominal yang Anda tentukan</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardPromo from './components/CardPromo.vue'
export default {
  components: {
    CardPromo
  },
  data() {
    return {
      promos: [],
      promoCurrentPage: 1,
      promoTotalPage: 1,
      promoLimit: 10,
      promoSortby: 'id.desc',
      promoSearch: '',
      promoStatus: 0,
    }
  },
  methods: {
    getPromo( page ) {
      let _ = this

      _.axios.get('/kfs-promotion'
          + '?page=' + page
          + '&limit=' + _.promoLimit
          + '&sortBy=' + _.promoSortby
          + '&status=' + _.promoStatus
          + '&kfsId=' + _.$route.params.id
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.promoCurrentPage = data.currentPage
            _.promoTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.promos.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getPromo(1)
  },
  watch: {
    promoStatus: function() {
      this.promos = []
      this.getPromo(1)
    }
  }
}
</script>