<template>
  <div>
    <div class="bg-white shadow rounded-12 p-3">
      <div class="row gx-2 justify-content-between mb-3">
        <div class="col-lg-10 col-7 col-sm-8">
          <div class="position-relative">
            <div class="form-search">
              <input type="text" class="form-control" name="" id="" placeholder="Cari kata kunci" v-model="search">
            </div>
          </div>
        </div>
        <div class="col-5 col-lg-2 col-sm-4">
          <a class="btn btn-primary w-100 create-medical text-nowrap cancel" @click="resetUpload()">Import</a>
        </div>
      </div>
      <Table :table="table"/>
    </div>

    <div class="overlay-popup ">
      <div id="cancel" class="box-md hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Import Data</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <label><a :href="`${$baseUrl}templates/format-import-old-transaction.xlsx`" class="text-xs">Download Format Import Data</a></label>
          <input type="file" class="form-control mb-3" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Batal</button>
          </div>
          <div class="col-6">
            <button class="btn btn-border-primary w-100 close-btn" :disabled="!fileUpload" @click="importTransaction()">Import</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'
export default {
	components: {
    Table
	},
  data() {
    return {
      allowedExtension: ['xlsx'],
      table: {
        options: {
          columns: [
            { name: 'Invoice', sortable: true, reference: 'invoiceCode' },
            { name: 'Nama', sortable: true, reference: 'fullName' },
            { name: 'Status', sortable: true, reference: 'status' },
            { name: 'Invoice Date', sortable: true, reference: 'invoiceDate' },
            { name: 'Due Date', sortable: true, reference: 'dueDate' },
            { name: 'Location', sortable: true, reference: 'since' },
            { name: 'Total Tip', sortable: true, reference: 'totalTips' },
            { name: 'Gross Total', sortable: true, reference: 'grossTotal' },
          ],
          rows: [
            { reference: 'invoiceCode', type: 'text' },
            { reference: 'fullName', type: 'text' },
            { reference: 'status', type: 'text' },
            { reference: 'invoiceDate', type: 'date' },
            { reference: 'dueDate', type: 'date' },
            { reference: 'since', type: 'text' },
            { reference: 'totalTips', type: 'currency' },
            { reference: 'grossTotal', type: 'currency' },
          ],
        },
        data: []
      },
      page: 1,
      limit: 10,
      sortby: 'id.desc',
      search: '',
      selected: [],
      fileUpload: null,
    }
  },
  methods: {
    get() {
      let _ = this
      _.axios.get('/old-transaction'
        + '?page=' + _.page
        + '&limit=' + _.limit
        + '&sortBy=' + _.sortby
        + '&search=' + _.search
        + '&kfsId=' + _.$route.params.id
      )
      .then( resp => {
        _.table.data = resp.data.data
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    },
    handleUpload(event){
      let _ = this

      let file = event.target.files[0]
      _.fileUpload = file
    },
    resetUpload() {
      $("#upload").val(null);
      this.fileUpload = null
    },
    importTransaction() {
      let _ = this
      let formData = new FormData
      formData.append('file', _.fileUpload)
      formData.append('kfsId', _.$route.params.id)

      _.axios.post('/old-transaction/import', formData)
        .then(() => {
          _.$notify({
            type: 'success',
            title: 'Import Transaksi Berhasil'
          });
          _.get()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
  mounted() {
    let _ = this

    _.get()

    _.$root.$on("changePage", (data) => {
      _.page = data
    });
    _.$root.$on("changeLimit", (data) => {
      _.limit = data
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.sortby = 'id.desc'
      } else {
        _.sortby = data
      }
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  },
  watch: {
    page: function(){
      this.get()
    },
    limit: function(){
      this.get()
    },
    sortby: function(){
      this.get()
    },
    search: function() {
      this.get()
    }
  }
}
</script>