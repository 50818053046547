<template>
  <div class="col-xl-4 col-lg-6">
    <router-link :to="'/home/sales/' + data.id" class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="d-flex align-items-center col-6">
          <div class="bullet" :class="'bg-' + salesStatusColor(data.status)"></div>
          <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + salesStatusColor(data.status)">{{ salesStatusText(data.status) }}</div>
        </div>
        <div class="col-6 text-hidden text-sm color-gray-60 text-right">{{ data.poNumber }}</div>
      </div>
      <div class="text-xs text-italic color-gray-80 text-hidden" v-if="salesStatusSubtitle(data.status)">{{ salesStatusSubtitle(data.status) }}</div>
      <hr class="dashed">
      <div class="d-flex align-items-baseline mb-2" v-if="data.acceptedBy && data.status != -2">
        <div class="col-auto">
          <div class="circle bg-orange"></div>
        </div>
        <div class="col ps-2" style="width: -webkit-fill-available;">
          <div class="text-sm  color-gray-60 text-hidden">Permintaan Untuk </div>
          <div class="text-sm text-medium color-gray-100 text-hidden">{{ data.acceptedBy }}, {{ data.acceptedBySince }}</div>
        </div>
      </div>
      <div class="d-flex align-items-baseline">
        <div class="col-auto">
          <div class="circle bg-green"></div>
        </div>
        <div class="col ps-2" style="width: -webkit-fill-available;">
          <div class="text-sm  color-gray-60 text-hidden">Permintaan Dari </div>
          <div class="text-sm text-medium color-gray-100 text-hidden">{{ data.requestBy }}, {{ data.requestBySince }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>