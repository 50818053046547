<template>
  <div>
    <section v-if="state == 'detail'">
      <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Detail Order</a>

      <div class="row ">
        <div class="col-lg-7 mb-4">
          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
            <div class="d-flex align-items-center">
              <div class="bullet" :class="'bg-' + orderStatusColor(model.status)"></div>
              <div class="text-semiBold text-xs ms-3" :class="'color-' + orderStatusColor(model.status)">{{ orderStatusText(model.status) }}</div>
            </div>
            <div class="text-xs text-italic color-gray-80 text-hidden mt-2" v-if="model.status != -3 && orderStatusSubtitle(model.status)">{{ orderStatusSubtitle(model.status) }}</div>
            <div class="text-xs text-italic color-gray-80 text-hidden mt-2" v-else-if="orderStatusComplainText(model.statusComplain)">{{ orderStatusComplainText(model.statusComplain) }}</div>
          </div>

          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
            <div class="d-flex justify-content-between mb-3">
              <div class="col">
                <div class="text-xs color-gray-40 ">Nomor Order</div>
              </div>
              <div class="col-6">
                <div class="text-xs text-right color-gray-100 text-medium">{{ model.poNumber }}</div>
              </div>
            </div>
            <hr class="dashed">
            <div class="d-flex justify-content-between mb-3">
              <div class="col">
                <div class="text-xs color-gray-40 ">KFS Pemesan</div>
              </div>
              <div class="col-6">
                <div class="text-xs text-right color-gray-100 text-medium">{{ model.requestBy.name }}, {{ model.requestBy.since }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="col">
                <div class="text-xs color-gray-40 ">Tipe Stok</div>
              </div>
              <div class="col-6">
                <div class="text-xs text-right color-gray-100 text-medium">Stok {{ model.type == 1 ? 'Produk' : 'Bahan Baku' }}</div>
              </div>
            </div>
          </div>

          <div class="text-sm text-semiBold mb-3">Rincian Order </div>
          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
            <div class="d-flex justify-content-between mb-3" v-for="product in model.products" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.detail ? product.detail.name : '-' }}</div>
                <div class="text-xs color-gray-80">{{ product.qty }} x {{ product.unitPrice | currency}}</div>
              </div>
              <div class="col-auto">
                <div class="text-xs color-gray-100 text-medium">{{ product.qty * product.unitPrice | currency }}</div>
              </div>
            </div>          
            <div class="d-flex justify-content-between mb-3" v-for="product in model.raws" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.detail ? product.detail.name : '-' }}</div>
                <div class="text-xs color-gray-80">{{ product.qty }} x {{ product.unitPrice | currency}}</div>
              </div>
              <div class="col-auto">
                <div class="text-xs color-gray-100 text-medium">{{ product.qty * product.unitPrice | currency }}</div>
              </div>
            </div>   

            <section v-if="model.addPayment">
              <hr class="dashed">
              <div class="d-flex justify-content-between ">
                <div class="col">
                  <div class="text-xs color-gray-80 mb-1">Biaya Tambahan </div>
                  <div class="text-xs color-gray-80 text-italic">{{ model.addPaymentNote }}</div>
                </div>
                <div class="col-auto">
                  <div class="text-xs color-gray-100 text-medium">{{ model.addPayment | currency }}</div>
                </div>
              </div>
            </section>

            <hr class="dashed">

            <div class="d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="text-xs color-gray-40">Total Pembayaran </div>
              </div>
              <div class="col-auto">
                <div class="text-xs color-red-100 text-semiBold">{{ model.totalPayment + model.addPayment | currency }}</div>
              </div>
            </div>

            <hr class="dashed">

            <div class="d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="text-xs color-gray-40">Bonus Poin </div>
              </div>
              <div class="col-auto">
                <div class="text-xs col-auto text-bold d-flex align-items-center color-yellow">
                  <span><img src="/img/icn-coin.png" class="img-fluid"> </span><span class="ms-2">{{ model.poin | numFormat }}</span>
                </div>
              </div>
            </div>
          </div>

          <section v-if="[-3].includes(model.status)">
            <div class="text-sm text-semiBold mb-3">Rincian Komplain </div>
            <div class="bg-white rounded-12 pe-3 pt-3 ps-3 border-1 border-solid border-color-gray mb-3">
              <div class="d-flex justify-content-between mb-3" v-for="item in model.complaints[0].items" :key="item.id">
                <div class="col">
                  <div class="text-xs color-gray-80 mb-1">{{ item.name }}</div>
                  <div class="text-xs color-gray-80">{{ item.qty | numFormat }} x {{ item.unitPrice | currency }}</div>
                </div>
                <div class="col-auto">
                  <div class="text-xs color-gray-100 text-medium">{{ item.qty * item.unitPrice | currency }}</div>
                </div>
              </div>

              <hr class="dashed">

              <div class="text-xs color-gray-80 text-italic">{{ model.complaints[0].complain }}</div>

              <hr class="dashed">

              <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-3 border-1 border-solid border-color-gray mb-3" v-for="(attachment, index) in model.complaints[0].attachments" :key="index">
                <div class="d-flex justify-content-between">
                  <div class="col-auto align-self-center">
                    <a :href="attachment" target="_blank">
                      <img :src="!['png', 'jpg', 'jpeg'].includes(attachment.split('.').pop().toLowerCase()) ? '/img/ico_attachment.png' : attachment" class="img-fluid rounded-12" style="width: 50px; height: 50px; object-fit: cover;">
                    </a>
                  </div>
                  <div class="col pe-3 ps-3 align-self-center">
                    <div class="text-xs color-gray-80">
                      <a :href="attachment" target="_blank">{{ attachment.split('/').pop() }}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!--End List Product-->

        <div class="col-lg-5">
          <section v-if="model.status == 0"> <!-- Pengajuan -->
            <button type="button" class="btn btn-border-secondary cancel w-100">Batalkan Pesanan</button>
          </section>
          <section v-if="model.status == 1"> <!-- Menunggu Pembayaran -->
            <div class="bg-white rounded-20 bg-pay p-4">
              <p class="text-sm color-gray-40">Segera lakukan pembayaran sebelum</p>
              <!-- <div class="text-sm color-red-100 text-semiBold">11 September 2021, 20 : 00 WIB</div> -->
              <div class="text-sm color-red-100 text-semiBold">{{ model.expiredAt | moment('DD MMMM YYYY, HH:mm') }} WIB</div>
              <hr class="dashed">
              <div v-if="model.acceptedBy && banks.length > 0">
                <p class="text-sm text-italic">Silahkan transfer ke salah satu nomor rekening dibawah ini</p>
                <div v-for="bank in banks" :key="bank.id">
                  <div class="d-flex justify-content-between align-items-end">
                    <div class="d-flex col">
                      <div class="col-3"><img :src="bank.bank.logoUrl" class="img-fluid" style="width: 50px;"></div>
                      <div class="col ms-3">
                        <div class="text-sm">{{ bank.bank.name }}</div>
                        <div class="text-sm text-bold">A.n {{ bank.accountHolder }}</div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end col-5">
                      <div class="text-sm text-bold text-hidden">{{ bank.accountNumber }}</div>
                      <a href="#" class="text-medium text-sm ms-2" @click="copy(bank.accountNumber)">Copy</a>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
              <p v-else class="text-sm text-italic">Penerima order Anda belum memiliki akun bank, silakan hubungi Admin.</p>
              <p class="text-sm color-gray-40 mb-2">Total pembayaran</p>
              <div class="text-sm color-red-100 text-semiBold">{{ model.totalPayment + model.addPayment | currency }}</div>
            </div>
          </section>
          <section v-if="model.status == 3"> <!-- Sedang Diproses -->
            <p class="text-xs text-italic ">Pastikan setiap barang sudah sesuai dengan pesanan</p>
            <div class="row gx-3">
              <div class="col-6">
                <button type="button" class="btn btn-border-primary w-100 cancel">Komplain</button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-primary w-100 done">Selesai</button>
              </div>
            </div>
          </section>
          <section v-if="model.status == -3 && model.statusComplain == 1"> <!-- Komplain -->
            <button type="button" class="btn btn-primary w-100 done">Selesai</button>
          </section>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-else-if="state == 'komplain'">
      <a @click="state = 'detail'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Komplain</a>

      <div class="row ">
        <div class="col-lg-7 mb-4">
          <p class="text-xs text-italic">Silakan masukkan jumlah barang yang ingin dikomplain</p>
          <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-0 border-1 border-solid border-color-gray" v-if="model.products && model.products.length > 0">
            <div class="d-flex justify-content-between mb-3" v-for="product in model.products" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.detail ? product.detail.name : '-' }}</div>
                <div class="text-xs color-gray-80">{{ product.qty | numFormat }} x {{ product.unitPrice | currency }}</div>
              </div>
              <div class="col-auto">
                <div class="d-flex justify-content-center align-items-center">
                  <button @click="product.qtyRequest -= 1" :disabled="product.qtyRequest == 0"
                    class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                  </button>
                  <input type="number" class="form-control input-number" :min="0" :max="product.qty" :disabled="product.qty == 0"
                    v-model="product.qtyRequest" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                  <button @click="product.qtyRequest += 1" :disabled="product.qtyRequest >= product.qty"
                    class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-0 border-1 border-solid border-color-gray" v-else-if="model.raws && model.raws.length > 0">
            <div class="d-flex justify-content-between mb-3" v-for="product in model.raws" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.detail ? product.detail.name : '-' }}</div>
                <div class="text-xs color-gray-80">{{ product.qty | numFormat }} x {{ product.unitPrice | currency }}</div>
              </div>
              <div class="col-auto">
                <div class="d-flex justify-content-center align-items-center">
                  <button @click="product.qtyRequest -= 1" :disabled="product.qtyRequest == 0"
                    class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                  </button>
                  <input type="number" class="form-control input-number" :min="0" :max="product.qty" :disabled="product.qty == 0"
                    v-model="product.qtyRequest" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                  <button @click="product.qtyRequest += 1" :disabled="product.qtyRequest >= product.qty"
                    class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End List Product-->

        <div class="col-lg-5">
          <div class="mb-3">
            <label class="text-xs color-gray-40 mb-2">Masukkan alasan komplain </label>
            <div class="position-relative">
              <input type="text" class="form-control" name="" placeholder="" value="" v-model="complain">
            </div>
          </div>
          <div class="mb-3">
            <label class="text-xs color-gray-40 mb-2">Lampiran</label>
            <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-3 border-1 border-solid border-color-gray">
              <div class="bg-white rounded-12 pt-3 ps-3 pe-3 pb-3 border-1 border-solid border-color-gray mb-3" v-for="(attachment, index) in attachments" :key="index">
                <div class="d-flex justify-content-between">
                  <div class="col-auto align-self-center">
                    <a :href="attachment.url" target="_blank">
                      <img :src="attachment.type == 'video' ? '/img/ico_attachment.png' : attachment.url" class="img-fluid rounded-12" style="width: 50px; height: 50px; object-fit: cover;">
                    </a>
                  </div>
                  <div class="col pe-3 ps-3 align-self-center">
                    <div class="text-xs color-gray-80">
                      <a :href="attachment.url" target="_blank">{{ attachment.name }}</a>
                    </div>
                  </div>
                  <div class="col-auto align-self-center">
                    <button type="button" class="btn btn-delete p-0" @click="removeAttachment(index)"><img src="/img/icons/delete.svg" class="img-fluid"></button>
                  </div>
                </div>
              </div>

              <p class="text-center text-xs p-3" v-if="attachments.length == 0">Silakan unggah lampiran</p>
              <input type="file" name="" id="upload" class="hidden" multiple v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
              <label for="upload" class="w-100" :style="isUploading ? 'pointer-events:none;' : ''">
                <div class="btn btn-primary w-100" 
                  :class="{ 'disabled': isUploading }">
                  {{ !isUploading ? 'Unggah Lampiran' : 'Mengunggah...' }}
                </div>
              </label>
              <div class="text-xs color-gray-40 col-12 col-sm-auto mt-1 ">Ukuran file: max {{ maxFileSize }} Mb/file. ({{ '.' + allowedExtension.join(' .').toUpperCase() }})</div>
            </div>
          </div>

          <button v-if="(model.products && model.products.length > 0) || (model.raws && model.raws.length > 0)" @click="postComplain()" class="btn btn-primary w-100" :disabled="requestComplain.items.length == 0 || !complain || attachments.length == 0">Kirim</button>
        </div>
        <!--End Right Side Bar-->

        <!-- <json-viewer :value="model"></json-viewer>
        <json-viewer :value="requestComplain"></json-viewer> -->
      </div>
    </section>
    <div class="overlay-popup">
      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-3" v-if="[0,1].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Batalkan Pesanan</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin membatalkan pesanan ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn" @click="updateStatus(-1)">Ya</a>
          </div>
        </div>
      </div>

      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-3" v-else>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Komplain Pesanan</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin Komplain pesanan ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn" @click="state = 'komplain'">Ya</a>
          </div>
        </div>
      </div>

      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-if="[-3].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Selesai</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin menyelesaikan komplain ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="updateStatusComplain(2)">Ya</a>
          </div>
        </div>
      </div>
      <div id="done" class="box-xs hidden bg-white rounded-12 p-3" v-else>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Selesai</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin menyelesaikan order ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="updateStatus(4)">Ya</a>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="requestComplain" :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'detail',
      model: {
        requestBy: {},
        products: [],
        complaints: []
      },
      complain: null,
      banks: [],
      attachments: [],
      maxFileSize: 5,
      allowedExtension: ['jpg', 'jpeg', 'png', 'mp4'],
      isUploading: false
    }
  },
  computed: {
    requestComplain: function() {
      let _ = this

    
      if ( _.model.products && _.model.products.length > 0 ) {
        return {
          complain: _.complain,
          items: _.model.products.filter( x => x.qtyRequest > 0 ).map( function(x) {
            return {
              id: x.detail.id,
              no: x.detail.no,
              itemTypeName: x.detail.itemTypeName,
              unitPrice: '' + x.unitPrice,
              name: x.detail.name,
              detailItemImage: JSON.stringify(x.detail.detailItemImage),
              description: x.detail.description,
              qty: x.qtyRequest + ''
            }
          }),
          attachments: _.attachments.map( x => x.url )
        }
      } else if ( _.model.raws && _.model.raws.length > 0) {
        return {
          complain: _.complain,
          items: _.model.raws.filter( x => x.qtyRequest > 0 ).map( function(x) {
            return {
              id: x.detail.id,
              no: x.detail.no,
              itemTypeName: x.detail.itemTypeName,
              unitPrice: '' + x.unitPrice,
              name: x.detail.name,
              detailItemImage: JSON.stringify(x.detail.detailItemImage),
              description: x.detail.description,
              qty: x.qtyRequest + ''
            }
          }),
          attachments: _.attachments.map( x => x.url )
        }
      }
    }
  },
  methods: {
    showOrder() {
      let _ = this

      _.axios.get('/purchase-orders/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data
          _.model.products = _.model.products.map( function(x) {
            return {
              ...x,
              qtyRequest: 0
            }
          })
          _.model.raws = _.model.raws.map( function(x) {
            return {
              ...x,
              qtyRequest: 0
            }
          })
          _.model.statusComplain = _.model.complaints.length > 0 ? _.model.complaints[0].status : null
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    updateStatus( status ) {
      let _ = this

      _.axios.put('/purchase-orders/' + _.$route.params.id + '/status', { status: status })
        .then(() => {
          _.showOrder()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    handleCustomQty( event, product ) {
      let _ = this
      product.qtyRequest = parseInt( event.target.value )
      if ( product.qty < event.target.value ) {
        product.qtyRequest = parseInt( product.qty )
      }
    },
    postComplain(){
      let _ = this

      _.axios.post('/purchase-orders/' + _.$route.params.id + '/complain', _.requestComplain)
        .then(() => {
          _.showOrder()
          _.state = 'detail'
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    updateStatusComplain( status ) {
      let _ = this

      _.axios.put('/purchase-orders/' + _.$route.params.id + '/status-complain', { status: status })
        .then(() => {
          _.showOrder()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getBank() {
      let _ = this

      _.axios.get('/purchase-orders/' + _.$route.params.id + '/banks')
        .then( resp => {
          _.banks = resp.data.data.filter( x => x.isDefault )
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    async handleUpload(event){
      let _ = this
      let files = event.target.files

      _.isUploading = true
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const upload = await _.upload( file, _.allowedExtension, _.maxFileSize)

        if (upload) {
          _.attachments.push({
            name: file.name,
            url: upload,
            type: fileExtension.includes(['mp4']) ? 'video' : 'image' 
          })
        }
      }
      _.isUploading = false
      $("#upload").val(null);
    },
    removeAttachment( index ) {
      this.attachments.splice(index, 1)
    }
  },
  mounted() {
    this.showOrder()
    this.getBank()
  },
  watch: {
    '$route.params.id': function(){
      this.showOrder()
      this.getBank()
    }
  }
}
</script>