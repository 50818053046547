<template>
  <div id="change-price" class="box-xs hidden bg-white rounded-4 p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-semiBold">Ajukan Ubah Harga</div>
      <button class="btn close-popup p-0" @click="requestedPrice = ''" type="button"><span class="icon-ico-close"></span></button>
    </div>
    <div class="content-popup">
      <div class="mb-3">
        <label class="text-xs color-gray-40 mb-2">Masukkan Harga </label>
        <div class="position-relative">
          <div class="icn-left ">
            <vue-numeric @keypress.native="numbersOnly" v-model="requestedPrice" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
          </div>
        </div>
      </div>
      <button class="btn btn-border-primary w-100" :disabled="!requestedPrice" @click="store()">Ajukan Perubahan</button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      requestedPrice: ''
    }
  },
  methods: {
    store() {
      let _ = this

      _.axios.put('/treatments/' + _.data.id + '/request-price/', {
          requestedPrice: _.requestedPrice
        })
        .then(() => {
          $('.close-popup').click()
          _.requestedPrice = ''

          _.$notify({
            type: 'success',
            title: 'Pengajuan Harga Berhasil',
            text: 'Pengajuan harga ' + _.data.name + ' berhasil. Silakan tunggu persetujuan Admin.'
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
}
</script>