<template>
  <div>
    <section v-if="state == 'product'">
      <a  @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Buat Order</a>

      <div class="row">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchProduct" :placeholder="'Cari ' +  (model.type == 1 ? 'Produk' : 'Bahan Baku')" v-model="productSearch">
            </div>
          </div>

          <div class="d-grid grid-product gap-3">
            <div v-for="product in products" :key="product.id"
              class="bg-white border-1 border-solid border-color-gray rounded-12 p-3">
              <div class="img-product center-content mb-3">
                <img :src="product.detailItemImage.length > 0 ? product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
              </div>
              <div class="text-xs text-medium text-center px-2 mb-2">{{ product.name }}</div>
              <!-- <div class="text-xs color-gray-60 text-center mb-2">Stok {{ product.availableToSell | numFormat }}</div> -->
              <!-- <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.unitPrice | currency }}</div> -->
              <div class="d-flex justify-content-center align-items-center">
                <button @click="product.qty -= 1, addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                </button>
                <input type="number" class="form-control input-number"
                  v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                <button @click="product.qty += 1, addOrSubProduct(product, 'add')"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                </button>
              </div>
            </div>
            <!--End List Product-->
          </div>
          <p class="text-center text-sm" v-if="products.length == 0">Tidak ada {{ model.type == 1 ? 'produk' : 'bahan baku' }}</p>
          <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
            <a class="color-gray-100" v-if="model.type == 1" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
            <a class="color-gray-100" v-if="model.type == 2" @click="getRawMaterials( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
          </p>
        </div>
        <!--End List Product-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button justify-content-between text-sm color-gray-100 collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ selectedProducts.length }} Terpilih</span>
                      <!-- <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPrice | currency }}</span> -->
                    </div>
                  </div>
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center py-3 px-4"
                  v-for="product in selectedProducts" :key="product.id">
                  <div>
                    <div class="text-xs text-medium mb-1">{{ product.name }}</div>
                    <!-- <div class="text-xs text-semiBold color-red-100">{{ product.unitPrice | currency }}</div> -->
                  </div>
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center">
                      <button @click="product.qty -= 1, addOrSubSelectedProduct(product, 'sub')" :disabled="product.qty == 0"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                      </button>
                      <input type="number" class="form-control input-number" :min="0"
                        v-model="product.qty" @change="handleCustomQtySelectedProduct($event, product)" @keypress="numbersOnly">
                      <button @click="product.qty += 1, addOrSubSelectedProduct(product, 'add')"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button type="button" @click="state = 'kfs'" class="btn btn-primary w-100" :disabled="selectedProducts.length == 0">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-if="state == 'kfs'">
      <a  @click="state = 'product'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Buat Order</a>
      <div class="row " v-if="state == 'kfs'">
        <div class="col-lg-7 mb-4">
          <div class="text-sm text-semiBold mb-3">KFS </div>
          <div class="position-relative mb-3">
            <label class="text-xs color-gray-40 mb-2">KFS Pemesan</label>
            <button type="button" class="form-control bg-white text-left" name="" id="choose-kfs">{{ selectedKfs ? selectedKfs.name : 'Pilih KFS' }}</button>
          </div>

          <!-- <label class="text-xs color-gray-40 mb-3">KFS Pemesan</label> -->
          <!-- <div class="row gx-3 ">
            <div class="col-sm-6 mb-3">
              <div class="choose-user">
                <input type="radio" class="hidden" id="sp" v-model="model.type" :value="1">
                <label for="sp" class="cursor-pointer"><span class="ms-4">Stok Produk</span> </label>
              </div>
            </div>
            <div class="col-sm-6 mb-3">
              <div class="choose-user">
                <input type="radio" class="hidden" id="si" v-model="model.type" :value="2">
                <label for="si" class="cursor-pointer"><span class="ms-4">Stok Ingredients</span> </label>
              </div>
            </div>
          </div> -->

          <div class="text-sm text-semiBold mb-3">Rincian Order </div>
          <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray">
            <div class="d-flex justify-content-between" :class="{ 'mb-3': selectedKfs }" v-for="product in selectedProducts" :key="product.id">
              <div class="col">
                <div class="text-xs color-gray-80 mb-1">{{ product.name }}</div>
                <div class="text-xs color-gray-80" v-if="selectedKfs">{{ product.qty | numFormat }} x {{ product.unitPrice | currency }}</div>
              </div>
              <div class="col-auto" v-if="selectedKfs">
                <div class="text-xs color-gray-100 text-medium">{{ product.qty * product.unitPrice | currency }}</div>
              </div>
            </div>

            <hr class="dashed" v-if="selectedKfs">

            <div class="d-flex justify-content-between align-items-center" v-if="selectedKfs">
              <div class="col">
                <div class="text-xs color-gray-40">Total Pembayaran </div>
              </div>
              <div class="col-auto">
                <div class="text-xs color-red-100 text-semiBold">{{ totalPrice | currency }}</div>
              </div>
            </div>

            <hr class="dashed" v-if="selectedKfs">

            <div class="d-flex justify-content-between align-items-center" v-if="selectedKfs">
              <div class="col">
                <div class="text-xs color-gray-40">Bonus Poin </div>
              </div>
              <div class="col-auto">
                <div class="text-xs col-auto text-bold d-flex align-items-center color-yellow">
                  <span><img src="/img/icn-coin.png" class="img-fluid"> </span><span class="ms-2">{{ poin | numFormat }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End List Product-->

        <div class="col-lg-5">
          <div class="bg-white rounded-10 border-1 border-solid border-color-gray p-3 mb-3">
            <div class="d-flex text-xs justify-content-between align-items-center">
              <span class="color-gray-60">Total Pembayaran</span>
              <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPrice | currency }}</span>
            </div>
          </div>

          <button type="button" class="btn btn-primary w-100" :disabled="!request.kfsId" @click="createPurchaseOrder()">Buat Order</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-if="state == 'finish'">
      <div class="center-pay center-content flex-column">
        <div class="col-sm-10 col-lg-7 col-xl-6">
          <div class="bg-white rounded-20 bg-pay p-4 mb-4">
            <div class="text-center">
              <div class="text-sm text-semiBold mb-2">Order Berhasil Dibuat</div>
              <p class="text-sm mb-0">Tunggu pihak distributor menanggapi pesanan Anda</p>
            </div>
          </div>

          <div class="pt-0">
            <router-link :to="'/home/po/' + poId" class="btn btn-border-primary w-100">Lihat Detail Order</router-link>
            <router-link to="/home" class="w-100 text-center d-block text-sm text-medium py-4">Kembali Ke Home</router-link>
          </div>

        </div>
      </div>
    </section>

    <div class="overlay-popup">
      <ChooseKfsRadio/>
    </div>

    <!-- <json-viewer
      :value="request"
      :expand-depth="5"
      copyable
      boxed
      sort></json-viewer> -->
  </div>
</template>
<script>
import ChooseKfsRadio from '@/components/modals/ChooseKfsRadio'

export default {
  components: {
    ChooseKfsRadio
  },
  data() {
    return {
      state: 'product',
      
      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',

      model: {
        type: 1,
        products: []
      },

      poId: null,
      poin: 0,
      selectedKfs: null,
      selectedProducts: []
    }
  },
  computed: {
    request: function() {
      let _ = this

      let payload = {
        type: _.model.type,
        kfsId: _.selectedKfs ? _.selectedKfs.id : null
      }

      if ( _.model.type == 1 ) { // Produk
        payload.products = _.selectedProducts.map( function( x ) {
          return {
            accuProductId: x.id,
            unitPrice: parseInt(x.unitPrice),
            qty: x.qty,
          }
        })
      } else if ( _.model.type == 2 ) { // Bahan Baku
        payload.raws = _.selectedProducts.map( function( x ) {
          return {
            accuRawMaterialId: x.id,
            unitPrice: parseInt(x.unitPrice),
            qty: x.qty,
          }
        })
      } 

      return payload
    },
    totalPrice: function() {
      let total = 0
      this.selectedProducts.forEach(elm => {
        total += elm.unitPrice * elm.qty
      });
      return total
    },
    requestValidatePO: function() {
      let _ = this

      let payload = {
        type: _.model.type,
        kfsId: _.selectedKfs ? _.selectedKfs.id : null
      }

      if ( _.model.type == 1 ) { // Produk
        payload.products = _.selectedProducts.map( function( x ) {
          return {
            accuProductId: x.id,
            qty: x.qty,
          }
        })
      } else if ( _.model.type == 2 ) { // Bahan Baku
        payload.raws = _.selectedProducts.map( function( x ) {
          return {
            accuRawMaterialId: x.id,
            qty: x.qty,
          }
        })
      } 

      return payload
    },
  },
  methods: {
    getProduct( page ) {
      let _ = this
      _.axios.get('/misc/products'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.forEach(elm => {
              let qty = 0
              let searchProduct = _.selectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                qty = _.selectedProducts[searchProduct].qty
              }
              _.products.push({ ...elm, qty: qty })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getRawMaterials( page ) {
      let _ = this
      _.axios.get('/misc/raw-materials'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.forEach(elm => {
              let qty = 0
              let searchProduct = _.selectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                qty = _.selectedProducts[searchProduct].qty
              }
              _.products.push({ ...elm, qty: qty })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    calculatePoin() {
      let _ = this
      _.axios.get('/purchase-orders/calculate-point?payment=' + _.totalPrice + '&level=' + _.selectedKfs.level)
        .then( resp => {
          _.poin = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    validatePO() {
      let _ = this

      _.axios.post('/purchase-orders/validation', _.requestValidatePO)
        .then( resp => {
          resp.data.data.products.forEach(product => {
            let indexSelectedProduct = null
            if (_.model.type == 1) {
              indexSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.accuProductId )
            } else {
              indexSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.accuRawMaterialId )
            }

            _.selectedProducts[indexSelectedProduct].unitPrice = parseInt(product.unitPrice)
            _.$forceUpdate()
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchProduct() {
      let _ = this
      _.products = []
      if ( _.model.type == 1 ) {
        _.getProduct(1)
      } else {
        _.getRawMaterials(1)
      }
    },
    createPurchaseOrder() {
      let _ = this
      _.axios.post('/purchase-orders', _.request)
        .then( resp => {
          _.poId = resp.data.data.id
          _.state = 'finish'
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    addOrSubProduct( product, type ) {
      let _ = this

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchSelectedProduct != -1 ) {
          _.selectedProducts[searchSelectedProduct].qty += 1
        } else {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.selectedProducts[searchSelectedProduct].qty == 1 ) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        } else {
          _.selectedProducts[searchSelectedProduct].qty -= 1
        }
      }
    },
    addOrSubSelectedProduct( product, type ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchProduct != -1 ) {
          _.products[searchProduct].qty += 1
        } else {
          _.products.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.products[searchProduct].qty == 1 ) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
        }
        _.products[searchProduct].qty -= 1
      }
    },
    handleCustomQty( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.availableToSell < event.target.value ) {
        product.qty = parseInt( product.availableToSell )
      }

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
      if ( searchSelectedProduct != -1 ) {
        _.selectedProducts[searchSelectedProduct].qty = product.qty
        if (product.qty == 0) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        }
      } else {
        if (product.qty > 0) {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      }
    },
    handleCustomQtySelectedProduct( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.availableToSell < event.target.value ) {
        product.qty = parseInt( product.availableToSell )
      }

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      if ( searchProduct != -1 ) {
        _.products[searchProduct].qty = product.qty
        if (product.qty == 0) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
        }
      }
    }
  },
  mounted() {
    let _ = this

    _.model.type = parseInt(_.$route.query.type)
    if ( _.model.type == 1 ) {
      _.getProduct(1)
    } else {
      _.getRawMaterials(1)
    }

    _.$root.$on("setKfs", (data) => {
      _.selectedKfs = data
    });
  },
  watch: {
    productLimit: function(){
      let _ = this
      if ( _.model.type == 1 ) {
        _.getProduct(1)
      } else {
        _.getRawMaterials(1)
      }
    },
    productSortby: function(){
      let _ = this
      if ( _.model.type == 1 ) {
        _.getProduct(1)
      } else {
        _.getRawMaterials(1)
      }
    },
    selectedKfs: function() {
      this.calculatePoin()
      this.validatePO()
    },
    state: function() {
      if ( this.state == 'kfs' ) {
        if ( this.selectedKfs ) {
          this.validatePO()
        }
      }
    }
  }
}
</script>