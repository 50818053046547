<template>
  <div>
    <div class="inner-home">
      <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="col">
            <div class="position-relative">
              <div class="form-search">
                <input type="text" class="form-control" name="" id="" placeholder="Cari nama KFS" v-debounce:300.lock="searchKfs" v-model="kfsSearch">
              </div>
            </div>
          </div>
          <div class="col-auto ms-3">
            <a @click="exportKfs()" class="text-base filter text-medium">Export</a>
          </div>
        </div>

        <div class="row gx-3">
          <div class="col-xl-4 col-sm-6" v-for="kfs in kfses" :key="kfs.id">
            <router-link :to="'/kfs/' + kfs.id"
              class="bg-white d-block rounded-10 border-1 border-solid border-color-gray p-3 mb-3">
              <div class="d-flex align-items-lg-center  align-items-start">
                <div class="img-place">
                  <img :src="kfs.photo ? kfs.photo : '/img/place.jpg'">
                </div>
                <div class="text-sm text-medium color-gray-100 ms-3">{{ kfs.name }}, {{ kfs.since }}</div>
              </div>
              <hr class="dashed">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="text-xs color-gray-80 mb-1">Karyawan</div>
                  <div class="text-xs text-medium color-gray-100">{{ kfs.employeeCount | numFormat }}</div>
                </div>
                <div>
                  <div class="text-xs color-gray-80 mb-1">Treatment</div>
                  <div class="text-xs text-medium color-gray-100">{{ kfs.treatmentCount | numFormat }}</div>
                </div>
                <div>
                  <div class="text-xs color-gray-80 mb-1">Produk</div>
                  <div class="text-xs text-medium color-gray-100">{{ kfs.productCount | numFormat }}</div>
                </div>
                <div>
                  <div class="text-xs color-gray-80 mb-1">Promo</div>
                  <div class="text-xs text-medium color-gray-100">{{ kfs.promoCount | numFormat }}</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <p class="text-center text-sm mt-2" v-if="kfses.length == 0">Tidak ada KFS</p>
        <p class="text-center text-sm mt-2" v-if="kfses.length > 0 && kfsCurrentPage < kfsTotalPage">
          <a class="color-gray-100" @click="getKfs( kfsCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
        </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      kfses: [],
      kfsCurrentPage: 1,
      kfsTotalPage: 1,
      kfsLimit: 10,
      kfsSortby: 'id.desc',
      kfsSearch: '',
    }
  },
  methods: {
    getKfs( page ) {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + page
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
          + '&search=' + _.kfsSearch
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsCurrentPage = data.currentPage
          _.kfsTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.kfses.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchKfs() {
      this.kfses = []
      this.getKfs(1)
    },
    exportKfs() {
      let _ = this

      _.axios.get('/kfs/export'
          + (_.kfsSearch ? '&search=' + _.kfsSearch : '')
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'KFS-' + _.$moment().format('YYYYMMDDHHmmsss') + '.xlsx'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    let _ = this
    _.getKfs(1)
  },
  watch: {
    kfsLimit: function(){
      this.getKfs(1)
    },
    kfsSortby: function(){
      this.getKfs(1)
    },
  }
}
</script>