<template>
  <div>
    <p class="text-sm text-italic">Bila ingin menambah treatment silahkan kontak admin pusat <a href="#">disini</a></p>
    <div class="row gx-3" v-if="treatments.length > 0">
      <Card v-for="treatment in treatments" :key="treatment.id" :data="treatment"/>
    </div>
    <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada treatment</p>
    <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
      <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>

    <div class="overlay-popup">
      <ModalDetail :data="treatmentDetail" v-if="treatmentDetail"/>
      <ModalPengajuan :data="treatmentDetail" v-if="treatmentDetail"/>
    </div>
  </div>
</template>
<script>
import Card from './Card.vue'
import ModalDetail from './ModalDetail.vue'
import ModalPengajuan from './ModalPengajuan.vue'

export default {
  components: {
    Card,
    ModalDetail,
    ModalPengajuan
  },
  data() {
    return {
      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentDetail: null
    }
  },
  methods: {
    getTreatment( page ) {
      let _ = this

      _.axios.get('/treatments'
          + '?kfsId=' + _.$route.params.id
          + '&page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.treatmentCurrentPage = data.currentPage
            _.treatmentTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.treatments.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    let _ = this
    
    _.getTreatment(1)
    _.$root.$on('setTreatmentDetail', (data) => {
      _.treatmentDetail = data
    })
  }
}
</script>