import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
	  accessToken: null,
  },
  getters: {
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.accessToken = payload.accessToken
      state.user = payload

      localStorage.setItem('accessToken', payload.accessToken)
      localStorage.setItem('user', JSON.stringify(payload))
    },
    LOGOUT_USER(state) {
      state.accessToken = null
      state.user = null

      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('UPDATE_USER', payload)
    },
    tryAutoLogin({ commit }) {
      let updateUser = {
        accessToken: localStorage.getItem('accessToken'),	
        user: JSON.parse(localStorage.getItem('user'))				
      }
      commit('UPDATE_USER', updateUser)
    },
    logout({ commit }) {
      commit('LOGOUT_USER')
    }
  },
  modules: {
  }
})
