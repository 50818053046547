<template>
  <div>
    <div class="mt-4 ">
      <nav>
        <div class="nav nav-tabs" id="information-tab" role="tablist">
          <button class="nav-link active" id="bantuan-tab" data-bs-toggle="tab" data-bs-target="#bantuan" type="button"
            role="tab" aria-controls="bantuan" aria-selected="true">FAQ</button>
          <button class="nav-link" id="tentang-kfs-tab" data-bs-toggle="tab" data-bs-target="#tentang-kfs" type="button"
            role="tab" aria-controls="tentang-kfs" aria-selected="true">Kebijakan Privasi</button>
          <button class="nav-link" id="syarat-tab" data-bs-toggle="tab" data-bs-target="#syarat" type="button"
            role="tab" aria-controls="syarat" aria-selected="true">Syarat & Ketentuan</button>
            
        </div>
      </nav>
      <div class="tab-content py-4">
        <div class="tab-pane fade show active" id="bantuan" role="tabpanel" aria-labelledby="bantuan-tab"
          tabindex="0">
          <div v-for="(faq, index) in faqs" :key="index">
            <p class="text-sm color-red-100 text-semiBold">{{ index+1 }} . {{ faq.question }}</p>
            <p class="text-sm" v-html="faq.answer"></p>
          </div>
        </div>
        <div class="tab-pane fade" id="tentang-kfs" role="tabpanel" aria-labelledby="tentang-kfs-tab" tabindex="0">
          <p class="text-sm" v-html="privacyPolicy"></p>
        </div>
        <div class="tab-pane fade" id="syarat" role="tabpanel" aria-labelledby="syarat-tab" tabindex="0">
          <p class="text-sm" v-html="termOfUse"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqs: [],
      privacyPolicy: null,
      termOfUse: null
    }
  },
  methods: {
    getFaq() {
      let _ = this
      _.axios.get('/faq?status=true')
        .then( resp => {
          _.faqs = resp.data.data.rows
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getPageContent() {
      let _ = this
      _.axios.get('/page-content')
        .then( resp => {
          const data = resp.data.data.rows
          
          let searchTermOfUse = data.map( x => x.type ).indexOf(1)
          if ( searchTermOfUse != -1 ) {
            _.termOfUse = data[searchTermOfUse].content
          }

          let searchPrivacyPolicy = data.map( x => x.type ).indexOf(2)
          if ( searchPrivacyPolicy != -1 ) {
            _.privacyPolicy = data[searchPrivacyPolicy].content
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
  mounted() {
    this.getFaq()
    this.getPageContent()
  },
}
</script>