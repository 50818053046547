<template>
  <div>
    <div class="inner-home">
        <div class="row gx-xxl-5 mb-4">
          <div class="col-lg-6">
            <router-link to="/home/point" class="bg-poin p-4 rounded-3 d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <div class="text-xs text-medium color-white">Poin</div>
                <!-- <div class="text-xs color-red-40 text-italic ms-3  text-hidden" v-if="point > 0">100 poin akan kadaluarsa</div> -->
              </div>
              <div class="d-flex align-items-center">
                <img src="/img/icn-coin.png" class="img-fluid">
                <div class="text-xs text-bold mx-2 color-white">{{ point | numFormat }}</div>
                <div class="rotate-180"><span class="icon-ico-back color-white"></span></div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="row gx-xl-5">
          <div class="col-lg-6 border-right mb-4">
            <div class="text-base color-gray-100 text-semiBold mb-3">Purchase Order</div>
            <div class="bg-white rounded-10 border-1 border-solid border-color-gray p-4 mb-4">
              <div class="row mb-4">
                <div class="col-4">
                  <router-link to="/home/po" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/pengajuan.png" class="img-fluid">

                        <div class="new-count" v-if="counterPurchaseOrder.submission > 0">{{ counterPurchaseOrder.submission > 99 ? '99+' : counterPurchaseOrder.submission }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Pengajuan</div>
                  </router-link>
                </div>
                <div class="col-4">
                  <router-link to="/home/po?orderStatus=allDiterima" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/diterima.png" class="img-fluid">

                        <div class="new-count" v-if="counterPurchaseOrder.received > 0">{{ counterPurchaseOrder.received > 99 ? '99+' : counterPurchaseOrder.received }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Diterima</div>
                  </router-link>
                </div>
                <div class="col-4">
                  <router-link to="/home/po?orderStatus=-2" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/ditolak.png" class="img-fluid">

                        <div class="new-count" v-if="counterPurchaseOrder.rejected > 0">{{ counterPurchaseOrder.rejected > 99 ? '99+' : counterPurchaseOrder.rejected }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Ditolak</div>
                  </router-link>
                </div>
              </div>

              <!-- <router-link to="/home/po/add" class="btn btn-border w-100">+ Buat Order</router-link> -->
              <button id="choose-po-type" class="btn btn-border w-100">+ Buat Order</button>
            </div>
            <div class="text-base color-gray-100 text-semiBold mb-3">Penjualan</div>
            <div class="bg-white rounded-10 border-1 border-solid border-color-gray p-4">
              <div class="row">
                <div class="col-4">
                  <router-link to="/home/sales" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/penjualan-permintaan.png" class="img-fluid">
                        <div class="new-count" v-if="counterPenjualan.submission > 0">{{ counterPenjualan.submission > 99 ? '99+' : counterPenjualan.submission }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Permintaan</div>
                  </router-link>
                </div>
                <div class="col-4">
                  <router-link to="/home/sales?salesStatus=allDiterima" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/penjualan-diterima.png" class="img-fluid">
                        <div class="new-count" v-if="counterPenjualan.received > 0">{{ counterPenjualan.received > 99 ? '99+' : counterPenjualan.received }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Diterima</div>
                  </router-link>
                </div>
                <div class="col-4">
                  <router-link to="/home/sales?salesStatus=-2" class="text-center">
                    <div class="center-content mb-2">
                      <div class="icn-center">
                        <img src="/img/penjualan-ditolak.png" class="img-fluid">
                        <div class="new-count" v-if="counterPenjualan.rejected > 0">{{ counterPenjualan.rejected > 99 ? '99+' : counterPenjualan.rejected }}</div>
                      </div>
                    </div>
                    <div class="text-hidden text-sm text-medium color-gray-100">Ditolak</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="text-base color-gray-100 text-semiBold mb-3">Grafik Penjualan</div>
            <div class="bg-white rounded-10 border-1 border-solid border-color-gray p-3 mb-4">
              <div class="d-flex justify-content-between align-items-center">
                <div class="col-6 col-sm-8">
                  <div class="d-flex align-items-center">
                    <div class="box-filter pilih-tanggal text-hidden cursor-pointer" 
                      @click="
                        datePickerTarget = 'startDate',
                        initialDate = startDate,
                        showDatePicker()
                      ">{{ startDate | moment('DD MMMM YYYY') }}</div>
                    <div class="text-sm mx-2">-</div>
                    <div class="box-filter pilih-tanggal text-hidden cursor-pointer" 
                      @click="
                        datePickerTarget = 'endDate',
                        initialDate = endDate,
                        showDatePicker()
                      ">{{ endDate | moment('DD MMMM YYYY') }}</div>
                  </div>
                </div>

                <div class="col-4 d-flex justify-content-end">
                  <div id="choose-kfs" class="box-filter cursor-pointer">
                    <span class="text-hidden" v-if="!selectedKfs">Semua KFS</span>
                    <span class="text-hidden" v-else>{{ selectedKfs.name.length > 15 ? selectedKfs.name.substring(1,15) + '...': selectedKfs.name }}</span>
                  </div>
                </div>
              </div>
              <div id="chart" v-if="showChart"></div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-base color-gray-100 text-semiBold">KFS</div>
              <router-link to="/kfs" class="text-sm">Lihat Semua</router-link>
            </div>

            <div class="swiper KFS">
              <div class="swiper-wrapper">
                
                <div class="swiper-slide" v-for="kfs in kfses" :key="kfs.id">
                  <router-link :to="'/kfs/' + kfs.id"
                    class="bg-white d-block rounded-10 border-1 border-solid border-color-gray p-3 mb-4">
                    <div class="d-flex align-items-lg-center  align-items-start">
                      <div class="img-place">
                        <img :src="kfs.photo ? kfs.photo : '/img/place.jpg'">
                      </div>
                      <div class="text-sm text-medium color-gray-100 ms-3">{{ kfs.name }}, {{ kfs.since }}</div>
                    </div>
                    <hr class="dashed">
                    <div class="d-flex justify-content-between">
                      <div>
                        <div class="text-xs color-gray-80 mb-1">Karyawan</div>
                        <div class="text-xs text-medium color-gray-100">{{ kfs.employeeCount | numFormat }}</div>
                      </div>
                      <div>
                        <div class="text-xs color-gray-80 mb-1">Treatment</div>
                        <div class="text-xs text-medium color-gray-100">{{ kfs.treatmentCount | numFormat }}</div>
                      </div>
                      <div>
                        <div class="text-xs color-gray-80 mb-1">Produk</div>
                        <div class="text-xs text-medium color-gray-100">{{ kfs.productCount | numFormat }}</div>
                      </div>
                      <div>
                        <div class="text-xs color-gray-80 mb-1">Promo</div>
                        <div class="text-xs text-medium color-gray-100">{{ kfs.promoCount | numFormat }}</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="overlay-popup">
      <DatepickerDrumRoll v-if="isShowDatePicker && datePickerTarget == 'startDate'"/>
      <DatepickerDrumRoll v-if="isShowDatePicker && datePickerTarget == 'endDate'"/>
      <ChooseKfsRadio />

      <div id="pilih-tipe-po" class="box-xs hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Buat Order</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <router-link to="/home/po/add?type=1" class="text-black">
            <p class="text-sm mb-0">Order Stok Produk <span class="icon-ico-chev-right" style="margin-top: 5px; float: right;"></span></p>
          </router-link>
          <hr style="margin-top: 10px; margin-bottom: 10px;"> 
          <router-link to="/home/po/add?type=2" class="text-black">
            <p class="text-sm mb-0">Order Stok Bahan Baku <span class="icon-ico-chev-right" style="margin-top: 5px; float: right;"></span></p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatepickerDrumRoll from '@/components/modals/DatepickerDrumRoll'
import ChooseKfsRadio from '@/components/modals/ChooseKfsRadio'

export default {
  components: {
    DatepickerDrumRoll,
    ChooseKfsRadio
  },
  computed: {
    request: function() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        kfsId: this.selectedKfs ? this.selectedKfs.id : null
      }
    }
  },
  data() {
    return {
      selectedKfs: null,
      chartOptions: {
        series: [{
          name: 'Rp ',
          data: [],
        }],
        chart: {
          type: 'area',
          height: 250,
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        fill: {
          type: 'gradient',
          colors: ['#B22A2E'],
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100, 100]
          }
        },
        markers: {
          size: 3,
          colors: ['#FBF3F3'],
          strokeColors: '#B22A2E',
          strokeWidth: 2,
          hover: {
            size: 5
          }
        },
        tooltip: {
          intersect: false,
          shared: false,
          style: {
            fontSize: '10px',
            fontFamily: 'Poppins'
          },
          marker: {
            show: false,
          },
          x: {
            show: false
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.config.series[seriesIndex].data[dataPointIndex];
            return `<div class="chart-tooltip">
                      ${
                        new Intl.NumberFormat("id-ID", { 
                          style: "currency", 
                          currency: "IDR", 
                          maximumFractionDigits: 0 
                        }).format(data.y)
                      }
                    <div>`;
          }
        },
        yaxis: {
          show: true,
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat("id-ID", { 
                style: "currency", 
                currency: "IDR", 
                maximumFractionDigits: 0 
              }).format(value)
            },
            offsetX: -15,
          }
        },
        grid: {
          show: true,
          borderColor: '#E9E9E9',
          strokeDashArray: 2,
          position: 'back',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
        }
      },
      isShowDatePicker: false,
      datePickerTarget: null,
      startDate: this.$moment().subtract(10, 'days').format('YYYY-MM-DD'),
      endDate: this.$moment().format('YYYY-MM-DD'),
      kfses: [],
      point: 0,
      counterPurchaseOrder: {
        submission: 0,
        received: 0,
        rejected: 0
      },
      counterPenjualan: {
        submission: 0,
        received: 0,
        rejected: 0
      },
      showChart: false
    }
  },
  methods: {
    showDatePicker() {
      this.isShowDatePicker = false
      setTimeout(() => {
        this.isShowDatePicker = true
      }, 100);
      setTimeout(() => {
        this.$root.$emit('setInitialDate', this[this.datePickerTarget])
      }, 100);
    },
    getKfs() {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + 1
          + '&limit=' + 5
          + '&sortBy=' + 'id.asc'
        )
        .then( resp => {
          _.kfses = resp.data.data.rows

          setTimeout(() => {
            new Swiper(".KFS", {
              slidesPerView: 'auto',
              spaceBetween: 10,
              autoHeight: true,
              breakpoints: {
                375: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 'auto',
                  spaceBetween: 16,
                },
              },
            })
          }, 100);
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getDaysBetweenDates(startDate, endDate) {
        var now = this.$moment(startDate), dates = [];
        while (now.isSameOrBefore(this.$moment(endDate))) {
            dates.push(now.format('DD MMM'));
            now.add(1, 'days');
        }
        return dates;
    },
    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    getGrafikPenjualan() {
      let _ = this
      let data = []
      this.showChart = false
      _.axios.get('/dashboard/chart-sales'
          + '?startDate=' + _.startDate
          + '&endDate=' + _.endDate
          + (this.selectedKfs ? '&kfsId=' + this.selectedKfs.id : '')
        )
        .then( resp => {
          resp.data.data.values.forEach(elm => {
            data.push({
              x: _.$moment(elm.period).format("DD MMM 'YY"),
              y: elm.value
            })
          });

          _.chartOptions.series[0].data = data
          _.$forceUpdate()

          this.showChart = true
          setTimeout(() => {
            var chart = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
            chart.render();
          }, 100);
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
          this.showChart = true
        })
    },
    getPoint() {
      let _ = this
      _.axios.get('/me/poin')
        .then( resp => {
          _.point = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getCounterPurchaseOrder() {
      let _ = this
      _.axios.get('/purchase-orders/badge')
        .then( resp => {
          _.counterPurchaseOrder = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getCounterPenjualan() {
      let _ = this
      _.axios.get('/purchase-orders/badge-sales')
        .then( resp => {
          _.counterPenjualan = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
  mounted() {
    let _ = this

    _.getKfs()
    _.getGrafikPenjualan()
    _.getPoint()
    _.getCounterPurchaseOrder()
    _.getCounterPenjualan()

    _.$root.$on("setKfs", (data) => {
      _.selectedKfs = data
    });

    _.$root.$on("setDate", (data) => {
      _[_.datePickerTarget] = data
    });
  },
  watch: {
    request: function() {
      this.getGrafikPenjualan()
    },
  }
}
</script>