<template>
  <div class="col-xl-4 col-lg-6">
    <div class="bg-white d-block rounded-12 p-3 mb-3">
      <div class="d-flex justify-content-between ">
        <div class="col d-flex align-items-center">
          <img :src="data.collectionImage && data.collectionImage.length > 0 ? data.collectionImage[0] : '/img/user-3.jpg'" class="treatment rounded-2">
          <div class="col ps-2">
            <div class="text-sm text-medium text-hidden">{{ data.name }}</div>
            <ul class="item-info mb-0">
              <li><span class="text-xs text-medium">{{ data.durationHours }} Jam {{ data.durationMinutes }} Menit</span></li>
              <li>
                <span class="text-xs color-red-100 text-semiBold">{{ data.price | currency }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-xs text-medium">
          <a @click="setTreatmentDetail()" class="text-md change-price color-gray-100"><span class="icon-ico-edit"></span></a>
        </div>
      </div>
      <hr class="dashed">
      <div v-if="data.collectionRawMaterial">
        <div class="d-flex justify-content-between align-items-center mb-2" v-for="rawMaterial in data.collectionRawMaterial.slice(0, 2)" :key="rawMaterial.id">
          <div class="text-xs color-gray-80">{{ rawMaterial.material ? rawMaterial.material.name : '-' }}</div>
          <div class="text-xs text-medium">{{ rawMaterial.maxUsage | numFormat }} {{ rawMaterial.unit }}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-if="data.collectionRawMaterial.length > 2">
          <div class="text-xs color-gray-80">{{ data.collectionRawMaterial.length - 2 }} Lainnya</div>
          <a @click="setTreatmentDetail()" class="text-xs detail-treatment text-medium">Lihat Detail</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  methods: {
    setTreatmentDetail() {
      this.$root.$emit('setTreatmentDetail', this.data)
    }
  },
}
</script>