<template>
  <div>
    <div class="setting-content" v-if="state == 'list'">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="col-6">
          <div class="text-lg text-semiBold">Daftar KFS Anda</div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button type="button" class="d-flex btn px-0 add-bank align-items-center text-hidden" @click="resetForm()">
            <span class="text-sm text-medium me-2">Tambah Akun Bank</span>
            <div class="btn-add px-3">+</div>
          </button>
        </div>
      </div>

      <a @click="state = 'detail', kfsDetail = kfs, kfsBanks = [], getKfsBank(1)" v-for="kfs in kfses" :key="kfs.id"
        class="d-flex align-items-center justify-content-between rounded-10 bg-white p-4 mb-3">
        <div class="col-lg-auto col-sm-9 col-10">
          <div class="d-flex align-items-center">
            <div class="me-3">
              <img :src="kfs.photo ? kfs.photo : '/img/place.jpg'" class="img-kfs">
            </div>
            <div class="col-10 col-sm-10 col-lg-auto">
              <div class="text-semiBold color-gray-100 mb-1">{{ kfs.name }}, {{ kfs.since }}
              </div>
              <div class="text-sm color-gray-100"><span class="color-red-100 text-semiBold">{{ kfs.bankCount | numFormat }}</span> <span
                  class="ps-2 text-hidden">Akun bank terdaftar</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <span class="icon-ico-chev-right text-xl"></span>
        </div>
      </a>

      <p class="text-center text-sm mt-2" v-if="kfses.length == 0">Tidak ada KFS</p>
      <p class="text-center text-sm mt-2" v-if="kfses.length > 0 && kfsCurrentPage < kfsTotalPage">
        <a class="color-gray-100" @click="getKfs( kfsCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
      </p>
    </div>
    <div class="setting-content" v-if="state == 'detail'">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="col-6">
          <a @click="state = 'list', kfses = [], getKfs(1)" class="text-lg color-gray-100 d-flex align-items-center text-semiBold"><span
              class="icon-ico-back me-2"></span><span class="db text-hidden">Daftar Akun Bank</span></a>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button type="button" class="d-flex btn px-0 add-bank align-items-center text-hidden" @click="resetForm()">
            <span class="text-sm text-medium me-2">Tambah Akun Bank</span>
            <div class="btn-add px-3">+</div>
          </button>
        </div>
      </div>

      <div
        class="bg-white border-color-gray rounded-12 p-3 p-sm-4 d-flex justify-content-between align-items-center mb-3" v-for="bank in kfsBanks" :key="bank.id">
        <div class="col-lg-auto col-sm-9 col-11">
          <div class="d-flex align-items-center">
            <div class="circle-big text-uppercase">
              {{ generateInitial(bank.accountHolder) }}
            </div>
            <div class="ps-3 col-10 col-sm-10 col-lg-auto">
              <div class="text-semiBold mb-2">{{ bank.accountHolder }}</div>
              <div class="d-flex mb-2">
                <div class="col-3 col-sm-auto"><img :src="bank.bank.logoUrl" class="img-fluid" style="width: 50px;"></div>
                <div class="col ms-3">
                  <div class="text-sm color-gray-60">{{ bank.accountNumber }}</div>
                </div>
              </div>
              <div class="color-red-100 text-sm d-flex align-items-center"><img src="/img/icons/pin.svg"
                  class="img-fluid"> <span class="ps-2 text-hidden">{{ bank.kfs.name }}, {{ bank.kfs.since }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <!-- <button class="btn btn-border-primary cancel w-100">Tolak</button> -->
          <div class="d-flex align-items-center">
          <button class="btn btn-border-primary btn-sm text-xs" v-if="bank.isDefault"><i class="icon-ico-check"></i> Rekening Utama</button>
          <button class="btn btn-primary btn-sm text-xs done" @click="bankDetail = bank" v-else>Set Utama</button>
          <button class="btn btn-delete cancel p-0 p-sm-3" type="button" @click="bankDetail = bank"><img src="/img//icons/delete.svg"
              class="img-fluid"></button>
          </div>
        </div>
      </div>
      <p class="text-center text-sm mt-2" v-if="kfsBanks.length == 0">Tidak ada Bank</p>
      <p class="text-center text-sm mt-2" v-if="kfsBanks.length > 0 && kfsBankCurrentPage < kfsBankTotalPage">
        <a class="color-gray-100" @click="getKfsBank( kfsBankCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
      </p>
    </div>

    <div class="overlay-popup">
      <div id="cancel" class="box-xs hidden bg-white rounded-12 p-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Hapus akun bank?</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apakah Anda yakin ingin menghapus akun bank ini?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <button class="btn btn-border-primary w-100" type="button" @click="deleteBank()">Ya</button>
          </div>
        </div>
      </div>

      <div id="add-bank" class="box-md hidden bg-white rounded-12 p-4">
        <ValidationObserver ref="bank"> 
          <form @submit.prevent="createBank()">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-semiBold text-base">Tambah Akun</div>
              <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
            </div>
            <ValidationProvider name="Bank" v-slot="{ errors }" rules="required" ref="bankId">
              <div class="mb-3 position-relative">
                <div class="text-xs color-gray-80 mb-1">Bank</div>
                <multiselect 
                  track-by="id" 
                  placeholder="Pilih Bank"
                  v-model="selectedBank"
                  @search-change="getBank"
                  :options="banks"
                  :multiple="false" 
                  :clear-on-select="false" 
                  :close-on-select="true"
                  :internal-search="false">

                  <template slot="singleLabel" slot-scope="{ option }">
                    <img class="option__image" :src="option.logoUrl" style="width: 40px;"/>
                    <span class="option__desc" style="margin-left: 25px;">
                      <span class="option__title" style="font-size: 14px; font-weight: 500;">{{ option.name }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="{ option }">
                    <img class="option__image" :src="option.logoUrl" style="width: 40px;"/>
                    <span class="option__desc" style="margin-left: 25px;">
                      <span class="option__title" style="font-size: 14px; font-weight: 500;">{{ option.name }}</span>
                    </span>
                  </template>
                  <span slot="noResult">Maaf, Bank tidak ditemukan</span>
                </multiselect>
                <input type="hidden" name="" v-model="request.bankId">
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider name="Nomor rekening" v-slot="{ errors }" rules="required" ref="accountNumber">
              <div class="mb-3 position-relative">
                <div class="text-xs color-gray-80 mb-1">Nomor Rekening</div>
                <div class="ab">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.accountNumber" @keypress="numbersOnly">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="accountHolder">
              <div class="mb-3 position-relative">
                <div class="text-xs color-gray-80 mb-1">Nama </div>
                <div class="ab">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.accountHolder">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider name="KFS" v-slot="{ errors }" rules="required" ref="kfsId">
              <div class="mb-0 position-relative">
                <div class="text-xs color-gray-80 mb-1">KFS</div>
                <multiselect 
                  track-by="id" 
                  placeholder="Pilih KFS"
                  v-model="selectedKfs"
                  @search-change="getKfsOption"
                  :options="kfsOptions"
                  :custom-label="customLabel"
                  :multiple="false" 
                  :clear-on-select="false" 
                  :close-on-select="true"
                  :internal-search="false"
                  :disabled="state == 'detail'">
                  <span slot="noResult">Maaf, KFS tidak ditemukan</span>
                </multiselect>
                <input type="hidden" v-model="request.kfsId">
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="center-content py-4">
              <button class="btn btn-primary w-50" type="submit">Tambahkan</button>
            </div>
          </form>
        </ValidationObserver>
      </div>

      <div id="done" class="box-xs hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Jadikan Rekening Utama</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <p class="text-sm">Apa Anda yakin menjadikan rekening ini menjadi rekening utama?</p>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Tidak</button>
          </div>
          <div class="col-6">
            <a class="btn btn-border-primary w-100 close-btn"  @click="setDefault()">Ya</a>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="request" :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'list',

      kfsDetail: null,
      bankDetail: null,
      
      kfses: [],
      kfsCurrentPage: 1,
      kfsTotalPage: 1,
      kfsLimit: 10,
      kfsSortby: 'id.desc',

      kfsBanks: [],
      kfsBankCurrentPage: 1,
      kfsBankTotalPage: 1,
      kfsBankLimit: 10,
      kfsBankSortby: 'id.desc',

      kfsOptions: [],
      selectedKfs: null,

      banks: [],
      bankCurrentPage: 1,
      bankTotalPage: 1,
      bankLimit: 5,
      bankSortby: 'name.asc',
      selectedBank: null,

      model: {
        kfsId: null,
        bankId: null,
        accountHolder: null,
        accountNumber: null
      }
    }
  },
  computed: {
    request: function() {
      let _ = this
      return {
        ..._.model,
        kfsId: _.selectedKfs ? _.selectedKfs.id : null,
        bankId: _.selectedBank ? _.selectedBank.id : null,
      }
    }
  },
  methods: {
    customLabel( kfs ) {
      return kfs.name + ', ' + kfs.since
    },
    getKfs( page ) {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + page
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsCurrentPage = data.currentPage
          _.kfsTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.kfses.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getKfsBank( page ) {
      let _ = this
      _.axios.get('/kfs-bank'
          + '?page=' + page
          + '&limit=' + _.kfsBankLimit
          + '&sortBy=' + _.kfsBankSortby
          + '&kfsId=' + _.kfsDetail.id
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsBankCurrentPage = data.currentPage
          _.kfsBankTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.kfsBanks.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getKfsOption( key ) {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + 1
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
          + '&search=' + (key ? key : '')
        )
        .then( resp => {
         _.kfsOptions = resp.data.data.rows
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getBank( key ) {
      let _ = this
      _.axios.get('/misc/bank'
          + '?page=' + _.bankCurrentPage
          + '&limit=' + _.bankLimit
          + '&sortBy=' + _.bankSortby
          + '&search=' + (key ? key : '')
        )
        .then( resp => {
         _.banks = resp.data.data.rows
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    resetForm() {
      let _ = this

      _.model = {
        kfsId: null,
        bankId: null,
        accountHolder: null,
        accountNumber: null
      }
      _.selectedKfs = null
      _.selectedBank = null

      if ( _.state == 'detail' && _.kfsDetail ) {
        _.selectedKfs = _.kfsDetail
      }

      _.$refs.bank.reset()
    },
    async createBank() {
      let _ = this
      
      const isValid = await _.$refs.bank.validate()
      if ( !isValid ) return

      _.axios.post('/kfs-bank', _.request)
        .then( resp => {
          if ( resp.data.success ) {

            let searchKfs = _.kfses.map( x => x.id ).indexOf( _.request.kfsId )
            if ( searchKfs != -1 ) {
              _.kfses[searchKfs].bankCount = parseInt(_.kfses[searchKfs].bankCount) + 1
              _.$forceUpdate()
            }
            
            $('.close-popup').click()

            _.kfsBanks = []
            _.getKfsBank(1)
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    },
    deleteBank() {
      let _ = this
      _.axios.delete('/kfs-bank/' + _.bankDetail.id)
        .then( resp => {
          if ( resp.data.success ) {
            $('.close-popup').click()
            _.kfsBanks = []
            _.getKfsBank(1)
            _.$notify({
              type: 'success',
              title: 'Successfully delete bank',
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    setDefault() {
      let _ = this
      _.axios.put('/kfs-bank/' + _.bankDetail.id + '/default')
        .then( resp => {
          if ( resp.data.success ) {
            $('.close-popup').click()
            _.kfsBanks = []
            _.getKfsBank(1)
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
  mounted() {
    this.getKfs(1)
    this.getBank()
    this.getKfsOption()
  },
}
</script>