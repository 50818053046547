import Vue from 'vue'

Vue.mixin({
  methods: {
    isAllowedExtension( filename, allowedExtension ) {
      let _ = this

      var fileExtension = filename.split('.').pop().toLowerCase();
      var isValidFile = false;

      for(var index in allowedExtension) {
        if(fileExtension === allowedExtension[index]) {
          isValidFile = true; 
          break;
        }
      }

      return isValidFile;
    },
    isAllowedFileSize( size, maxFileSize ) {
      if (size > ((1024 * 1024) * maxFileSize)) {
        return false
      }

      return true
    },
    async upload( file, allowedExtension = null, maxFileSize = null, refs = null  ){
      let _ = this

      if (allowedExtension && !_.isAllowedExtension(file.name, allowedExtension)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['Allowed file extensions are : *.' + allowedExtension.join(', *.')],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      if (maxFileSize && !_.isAllowedFileSize(file.size, maxFileSize)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['File is too large, allowed maximum size is '+ maxFileSize + ' Mb'],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      let formData = new FormData()
      formData.append('file', file)

      const res = await _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
        .then(resp => {
          return resp.data.data.file
        }).catch(err => {
          console.log(err.response)
        })

      return res
    },
    orderStatusColor( status ) {
      let color = ''
      switch ( status ) {
        case 0:
          color = 'blue'
          break;
        case 1:
          color = 'green'
          break;
        case 2:
          color = 'green'
          break;
        case 3:
          color = 'green'
          break;
        case 4:
          color = 'green'
          break;
        case -1:
          color = 'red-100'
          break;
        case -2:
          color = 'red-100'
          break;
        case -3:
          color = 'orange'
          break;
        case -4:
          color = 'red-100'
          break;
      
        default:
          break;
      }
      return color;
    },
    orderStatusText( status ) {
      let text = ''
      switch ( status ) {
        case 0:
          text = 'Pengajuan'
          break;
        case 1:
          text = 'Diterima'
          break;
        case 2:
          text = 'Diterima'
          break;
        case 3:
          text = 'Diterima'
          break;
        case 4:
          text = 'Diterima'
          break;
        case -1:
          text = 'Dibatalkan'
          break;
        case -2:
          text = 'Ditolak'
          break;
        case -3:
          text = 'Komplain'
          break;
        case -4:
          text = 'Kadaluarsa'
          break;
      
        default:
          break;
      }
      return text;
    },
    orderStatusSubtitle( status ) {
      let text = ''
      switch ( status ) {
        case 0:
          text = null
          break;
        case 1:
          text = 'Pesanan diterima silakan lanjutkan pembayaran'
          break;
        case 2:
          text = 'Menunggu Diproses'
          break;
        case 3:
          text = 'Pesanan Dalam Proses'
          break;
        case 4:
          text = 'Selesai'
          break;
        case -1:
          text = null
          break;
        case -2:
          text = null
          break;
      
        default:
          break;
      }
      return text;
    },
    salesStatusColor( status ) {
      let color = ''
      switch ( status ) {
        case false:
          color = 'blue'
          break;
        case 0:
          color = 'blue'
          break;
        case true:
          color = 'green'
          break;
        case 1:
          color = 'green'
          break;
        case 2:
          color = 'green'
          break;
        case 3:
          color = 'green'
          break;
        case 4:
          color = 'green'
          break;
        case -1:
          color = 'red-100'
          break;
        case -2:
          color = 'red-100'
          break;
        case -3:
          color = 'orange'
          break;
      
        default:
          break;
      }
      return color;
    },
    salesStatusText( status ) {
      let text = ''
      switch ( status ) {
        case false:
          text = 'Permintaan Barang'
          break;
        case 0:
          text = 'Permintaan Barang'
          break;
        case true:
          text = 'Diterima'
          break;
        case 1:
          text = 'Diterima'
          break;
        case 2:
          text = 'Diterima'
          break;
        case 3:
          text = 'Diterima'
          break;
        case 4:
          text = 'Diterima'
          break;
        case -1:
          text = 'Dibatalkan'
          break;
        case -2:
          text = 'Ditolak'
          break;
        case -3:
          text = 'Komplain'
          break;
      
        default:
          break;
      }
      return text;
    },
    salesStatusSubtitle( status ) {
      let text = ''
      switch ( status ) {
        case 0:
          text = null
          break;
        case true:
          text = 'Menunggu pembayaran dari pihak pemesan'
          break;
        case 1:
          text = 'Menunggu pembayaran dari pihak pemesan'
          break;
        case 2:
          text = 'Silakan kirim barang karna pihak pemesan telah melakukan pembayaran'
          break;
        case 3:
          text = 'Pesanan telah dikirim'
          break;
        case 4:
          text = 'Pesanan telah selesai'
          break;
        case -1:
          text = null
          break;
        case -2:
          text = null
          break;
      
        default:
          break;
      }
      return text;
    },
    employeeRoleText( role ) {
      let text = ''
      switch (role) {
        case 3:
          text = 'Kasir'
          break;
        case 4:
          text = 'Terapis'
          break;
      
        default:
          break;
      }
      return text
    },
    employeeRoleColor( role ) {
      let color = ''
      switch (role) {
        case 3:
          color = 'Orange'
          break;
        case 4:
          color = 'Tosca'
          break;
      
        default:
          break;
      }
      return color
    },
    genderText( gender ) {
      let text = ''
      switch (gender) {
        case 'laki':
          text = 'Laki Laki'
          break;
        case 'perempuan': 
          text = "Perempuan"
      
        default:
          break;
      }
      return text
    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault()
      } else {
          return true
      }
    },
    async copy( val ) {
      await navigator.clipboard.writeText(val);
      this.$notify({
        type: 'success',
        title: 'Berhasil disalin'
      })
    },
    promoTypeText( promoType ) {
      let text = ''
      switch (promoType) {
        case 1:
          text = 'Nominal'
          break;
        case 2:
          text = 'Persentase'
          break;
        case 3:
          text = 'Produk'
          break;
      
        default:
          break;
      }
      return text
    },
    promoStatusColor( status ) {
      let color = ''
      switch ( status ) {
        case 'pengajuan':
          color = 'blue'
          break;
        case 'diterima':
          color = 'tosca'
          break;
        case 'aktif':
          color = 'orange'
          break;
        case 'selesai':
          color = 'green'
          break;
        case 'ditolak':
          color = 'red-100'
          break;
        case 'tidak aktif':
          color = 'red-100'
          break;
        case 'dibatalkan':
          color = 'gray-60'
          break;
      
        default:
          break;
      }
      return color;
    },
    promoStatusText( status ) {
      let text = ''
      switch ( status ) {
        case 'pengajuan':
          text = 'Pengajuan'
          break;
        case 'diterima':
          text = 'Diterima'
          break;
        case 'aktif':
          text = 'Aktif'
          break;
        case 'selesai':
          text = 'Selesai'
          break;
        case 'ditolak':
          text = 'Ditolak'
          break;
        case 'tidak aktif':
          text = 'Ditolak'
          break;
        case 'dibatalkan':
          text = 'Dibatalkan'
          break;
      
        default:
          break;
      }
      return text;
    },
    generateInitial( string ) {
      var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
      
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    orderStatusComplainText( status ) {
      let text = ''
      switch (status) {
        case 0:
          text = 'Dalam Pengajuan'
          break;
        case 1:
          text = 'Dalam Proses'
          break;
        case 2:
          text = 'Selesai'
          break;
        case -1:
          text = 'Ditolak'
          break;
      
        default:
          break;
      }
      return text;
    }
  }
})