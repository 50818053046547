import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import Verification from '../views/auth/Verification.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import Home from '../views/home/Index.vue'
import HomeHistoriPoinIndex from '../views/home/HistoriPoin.vue'
import HomePurchaseOrderIndex from '../views/home/purchase_order/Index.vue'
import HomePurchaseOrderForm from '../views/home/purchase_order/Form.vue'
import HomePurchaseOrderDetail from '../views/home/purchase_order/Detail.vue'
import HomePenjualanIndex from '../views/home/penjualan/Index.vue'
import HomePenjualanDetail from '../views/home/penjualan/Detail.vue'

import KFSIndex from '../views/kfs/Index.vue'
import KFSDetail from '../views/kfs/detail/Index.vue'
import KFSEmployeeForm from '../views/kfs/detail/employee/Form.vue'
import KFSPromoDetail from '../views/kfs/detail/promo/Detail.vue'
import KFSPromoForm from '../views/kfs/detail/promo/Form.vue'

import PromoIndex from '../views/promo/Index.vue'
import PromoDetail from '../views/promo/Detail.vue'

import CustomerIndex from '../views/customer/Index.vue'
import CustomerForm from '../views/customer/Form.vue'
import CustomerDetail from '../views/customer/detail/Index.vue'

import SettingIndex from '../views/setting/Index.vue'
import InformationIndex from '../views/information/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import ('../layouts/Main.vue'),
    children: [
      {
          path: '/',
          redirect: '/home'
      },
      {
          path: '/home',
          name: 'Home',
          component: Home,
          meta: {
            navbarTitle: 'Beranda'
          }
      },
      {
          path: '/home/point',
          name: 'HomeHistoriPoinIndex',
          component: HomeHistoriPoinIndex,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/home/po',
          name: 'HomePurchaseOrderIndex',
          component: HomePurchaseOrderIndex,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/home/po/add',
          name: 'HomePurchaseOrderForm',
          component: HomePurchaseOrderForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
        path: '/home/po/:id',
        name: 'HomePurchaseOrderDetail',
        component: HomePurchaseOrderDetail,
        meta: {
          navbarTitle: ''
        }
      },
      {
          path: '/home/sales',
          name: 'HomePenjualanIndex',
          component: HomePenjualanIndex,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/home/sales/:id',
          name: 'HomePenjualanDetail',
          component: HomePenjualanDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/kfs',
          name: 'KFSIndex',
          component: KFSIndex,
          meta: {
            navbarTitle: 'KFS'
          }
      },
      {
          path: '/kfs/:id',
          name: 'KFSDetail',
          component: KFSDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/kfs/:id/employee/add',
          name: 'KFSEmployeeFormAdd',
          component: KFSEmployeeForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/kfs/:id/employee/:employeeId/edit',
          name: 'KFSEmployeeFormEdit',
          component: KFSEmployeeForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/kfs/:id/promo/add',
          name: 'KFSPromoFormAdd',
          component: KFSPromoForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/kfs/:id/promo/:promoId',
          name: 'KFSPromoDetail',
          component: KFSPromoDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/promo',
          name: 'PromoIndex',
          component: PromoIndex,
          meta: {
            navbarTitle: 'Promo'
          }
      },
      {
          path: '/promo/:id',
          name: 'PromoDetail',
          component: PromoDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/customer',
          name: 'CustomerIndex',
          component: CustomerIndex,
          meta: {
            navbarTitle: 'Customer Management'
          }
      },
      {
          path: '/customer/add',
          name: 'CustomerForm',
          component: CustomerForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/customer/:id',
          name: 'CustomerDetail',
          component: CustomerDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/setting',
          name: 'SettingIndex',
          component: SettingIndex,
          meta: {
            navbarTitle: 'Pengaturan'
          }
      },
      {
          path: '/information',
          name: 'InformationIndex',
          component: InformationIndex,
          meta: {
            navbarTitle: 'Pusat Informasi'
          }
      },
    ]
  },
  {
    path: '',
    component: () => import ('../layouts/Authentication.vue'),
    children: [
      {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/verification',
          name: 'Verification',
          component: Verification,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/reset-password',
          name: 'ResetPassword',
          component: ResetPassword,
          meta: {
            isGuest: true
          }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.meta.isGuest ) {
    next()
  } else if ( !store.state.accessToken && !localStorage.getItem('accessToken') ) {
    next({name: 'Login'})
  } else {
    next()
  }
})

export default router
