<template>
  <div>
    <div id="pilih-kfs" class="box-xs hidden bg-white rounded-12 p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="text-semiBold">Pilih KFS</div>
        <button class="btn close-popup p-0" type="button" @click="cancel()"><span class="icon-ico-close"></span></button>
      </div>
      <div class="content-popup mb-3">
        <div class="position-relative mb-2">
          <div class="form-search">
            <input type="text" v-debounce:300.lock="searchKfs" class="form-control" name="" id="" placeholder="Cari KFS" v-model="kfsSearch">
          </div>
        </div>
        <div style="max-height:300px;overflow:auto;">
          <div class="position-relative" v-if="$route.name == 'Home'">
            <div class="choose-staff">
              <input type="radio" class="hidden" :id="'all'" name="staff" :value="null" v-model="tempSelectedKfsId">
              <label :for="'all'" class="cursor-pointer"><span class="ms-4">Semua KFS</span>
              </label>
            </div>
          </div>
          <div class="position-relative" v-for="kfs in kfses" :key="kfs.id">
            <div class="choose-staff">
              <input type="radio" class="hidden" :id="'staff-' + kfs.id" name="staff" :value="kfs.id" v-model="tempSelectedKfsId">
              <label :for="'staff-' + kfs.id" class="cursor-pointer"><span class="ms-4">{{ kfs.name }}</span>
              </label>
            </div>
          </div>
        </div>

        <p class="text-center text-sm mt-2" v-if="kfses.length == 0">Tidak ada KFS</p>
        <p class="text-center text-sm mt-2" v-if="kfses.length > 0 && kfsCurrentPage < kfsTotalPage">
          <a class="color-gray-100" @click="getKfs( kfsCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
        </p>
      </div>
      <button class="btn btn-primary w-100 close-btn" type="button" @click="saveKfs()">Simpan</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      kfses: [],
      kfsCurrentPage: 1,
      kfsTotalPage: 1,
      kfsLimit: 10,
      kfsSortby: 'id.desc',
      kfsSearch: '',
      selectedKfs: null,
      selectedKfsId: null,
      tempSelectedKfsId: null
    }
  },
  methods: {
    getKfs( page ) {
      let _ = this
      _.axios.get('/me/kfs'
          + '?page=' + page
          + '&limit=' + _.kfsLimit
          + '&sortBy=' + _.kfsSortby
          + '&search=' + _.kfsSearch
        )
        .then( resp => {
          const data = resp.data.data
          _.kfsCurrentPage = data.currentPage
          _.kfsTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.kfses.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchKfs() {
      this.kfses = []
      this.getKfs(1)
    },
    saveKfs() {
      let _ = this
      let searchKfs = this.kfses.map( x => x.id ).indexOf( this.tempSelectedKfsId )
      if ( searchKfs != -1 ) {
        _.selectedKfs = this.kfses[searchKfs]
        _.selectedKfsId = _.selectedKfs.id
        this.$root.$emit('setKfs', _.selectedKfs)
      } else {
        this.$root.$emit('setKfs', null)
      }

      _.kfsSearch = ''
      _.searchKfs()
    },
    cancel() {
      let _ = this
      if ( !_.selectedKfsId && _.selectedKfsId != _.tempSelectedKfsId ) {
        _.tempSelectedKfsId = null
      } else {
        _.tempSelectedKfsId = _.selectedKfsId
      }

      _.kfsSearch = ''
      _.searchKfs()
    }
  },
  mounted() {
    let _ = this
    _.getKfs(1)
  },
  watch: {
    kfsLimit: function(){
      this.getKfs(1)
    },
    kfsSortby: function(){
      this.getKfs(1)
    },
  }
}
</script>