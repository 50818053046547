<template>
  <div>
    <ValidationObserver ref="observer"> 
      <form @submit.prevent="store()">
        <section v-show="state == 'products'">
          <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
              class="icon-ico-back me-2"></span>
            Buat Promo - Diskon {{ promoTypeText(model.promoType) }}</a>
          <div class="row">
            <div class="col-lg-7 mb-4">
              <div class="position-relative mb-3">
                <div class="form-search">
                  <input type="text" class="form-control" v-debounce:300.lock="searchProduct" :placeholder="'Cari Produk'" v-model="productSearch">
                </div>
              </div>

              <div class="text-sm text-medium mb-3">Pilih Produk</div>

              <div v-for="product in products" :key="product.id"
                class="position-relative border-1 border-solid border-color-gray rounded-12 bg-white me-2 me-lg-0 p-3 mb-3">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img :src="product.images.length > 0 ? product.images[0] : '/img/product-1.png'" class="img-48">
                  </div>
                  <div class="col">
                    <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
                    <ul class="item-info align-items-center mb-0">
                      <!-- <li><span class="text-xs">1 Jam 30 Mnt</span> </li> -->
                      <li>
                        <div class="color-red-100 text-xs text-semiBold">{{ product.price | currency }}</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="choose-produk">
                  <input type="checkbox" name="treatment" class="hidden" :id="'produk-' + product.id" 
                    @click="addOrRemoveProduct(product, $event)" :checked="selectedProducts.some((obj) => obj.id == product.id)">
                  <label :for="'produk-' + product.id" class="cursor-pointer"></label>
                </div>
              </div>
              <p class="text-center text-sm" v-if="products.length == 0">Tidak ada produk</p>
              <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
                <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <div class="col-lg-5">
              <div class="bg-white p-3 rounded-12 border-1 border-color-gray border-solid mb-3">
                <div class="text-xs mb-2">{{ selectedProducts.length }} Terpilih</div>
                <div class="d-flex justify-content-between align-items-center mt-2" v-for="product in selectedProducts" :key="product.id">
                  <div class="text-xs">{{ product.productName }}</div>
                  <div class="col-auto">
                    <div class="d-flex align-items-center">
                      <div class="text-xs color-red-100 text-semiBold">{{ product.price | currency }}</div>
                      <button class="btn p-0 ms-2" type="button" @click="removeProduct(product)">
                        <span class="icon-ico-minus-circle text-md color-gray-60"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <button @click="state = 'requirements'" type="button" class="btn btn-primary w-100" :disabled="selectedProducts.length == 0">Selanjutnya</button>
            </div>
          </div>
        </section>
        <section v-show="state == 'requirements'">
          <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4" v-if="model.promoType != 3"><span
              class="icon-ico-back me-2"></span>
            Buat Promo - Diskon {{ promoTypeText(model.promoType) }}</a>
          <a @click="state = 'products'" class="color-gray-100 d-inline-block text-semiBold mb-4" v-else><span
              class="icon-ico-back me-2"></span>
            Buat Promo - Diskon {{ promoTypeText(model.promoType) }}</a>
          <div class="row">
            <div class="col-lg-7 mb-4">
              <div class="text-sm text-semiBold color-gray-100 mb-3">Ketentuan Diskon </div>
              <div class="row gx-3">
                <div class="col-sm-12">
                  <ValidationProvider name="Nama promo" v-slot="{ errors }" rules="required" ref="name">
                    <div class="mb-3">
                      <label class="text-xs color-gray-40 mb-2">Nama Promo</label>
                      <div class="position-relative">
                        <input type="text" class="form-control" name="" placeholder="" value="" v-model="model.name">
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6" v-if="[1,2,3].includes(model.promoType)">
                  <div class="mb-3">
                    <label class="color-gray-40 text-xs mb-2">Pilih Target Pelanggan</label>
                    <div class="position-relative">
                      <div class="icn-chevron">
                        <select class="form-select" disabled>
                          <option>{{ kfs.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" v-if="[1,2,3].includes(model.promoType)">
                  <ValidationProvider name="Kode voucher" v-slot="{ errors }" rules="required" ref="voucherCode">
                    <div class="mb-3">
                      <label class="text-xs color-gray-40 mb-2">Kode Voucher</label>
                      <div class="position-relative">
                        <input type="text" class="form-control" name="" placeholder="" value="" v-model="model.voucherCode">
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12" v-if="[1,2,3].includes(model.promoType)">
                  <label class="text-xs color-gray-40 mb-2">Minimal Pembelian (Rp)</label>
                  <div class="row gx-3">
                    <div class="col-6 mb-2">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="nominal-1" name="nominal" v-model="isMinimalPembelian" :value="true">
                        <label for="nominal-1" class="cursor-pointer"><span class="ms-4">Tulis Nominal</span> </label>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="nominal-2" name="nominal" v-model="isMinimalPembelian" :value="false">
                        <label for="nominal-2" class="cursor-pointer"><span class="ms-4">Tidak Ada</span> </label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="isMinimalPembelian">
                      <ValidationProvider name="Minimal pembelian (Rp)" v-slot="{ errors }" rules="required" ref="minimumPayment">
                        <div class="position-relative">
                          <div class="icn-left-bg icn-left">
                            <vue-numeric @keypress.native="numbersOnly" v-model="model.minimumPayment" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" v-if="[1].includes(model.promoType)">
                  <label class="text-xs color-gray-40 mb-2">Nominal Diskon (Rp)</label>
                  <div class="row gx-3 mb-3">
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-5" name="disc-2" v-model="isNominalDiskonLainnya" :value="15000">
                        <label for="diskon-5" class="cursor-pointer"> {{ 15000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-6" name="disc-2" v-model="isNominalDiskonLainnya" :value="20000">
                        <label for="diskon-6" class="cursor-pointer"> {{ 20000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-7" name="disc-2" v-model="isNominalDiskonLainnya" :value="25000">
                        <label for="diskon-7" class="cursor-pointer"> {{ 25000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-8" name="disc-2" v-model="isNominalDiskonLainnya" :value="null">
                        <label for="diskon-8" class="cursor-pointer"> Lainnya</label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="isNominalDiskonLainnya == null">
                      <ValidationProvider name="Maks. diskon (Rp)" v-slot="{ errors }" rules="required|min_value:1" ref="discountPrice">
                        <div class="position-relative">
                          <div class="icn-left-bg icn-left">
                            <vue-numeric @keypress.native="numbersOnly" v-model="model.discountPrice" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" v-if="[2].includes(model.promoType)">
                  <label class="text-xs color-gray-40 mb-2">Persentase Diskon (%)</label>
                  <div class="row gx-3">
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-1" name="disc-1" v-model="isPersentaseDiskonLainnya" :value="15">
                        <label for="diskon-1" class="cursor-pointer"> 15%</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-2" name="disc-1" v-model="isPersentaseDiskonLainnya" :value="30">
                        <label for="diskon-2" class="cursor-pointer"> 30%</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-3" name="disc-1" v-model="isPersentaseDiskonLainnya" :value="50">
                        <label for="diskon-3" class="cursor-pointer"> 50%</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-4" name="disc-1" v-model="isPersentaseDiskonLainnya" :value="null">
                        <label for="diskon-4" class="cursor-pointer"> Lainnya</label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="isPersentaseDiskonLainnya == null">
                      <ValidationProvider name="Persentase diskon (%)" v-slot="{ errors }" rules="required|min_value:1|max_value:100" ref="discountPercentageMax">
                        <div class="position-relative">
                          <div class="icn-right-bg icn-right">
                            <input type="text" class="form-control" name="" id="" placeholder="" value="" v-model="model.discountPercentage">
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" v-if="[2].includes(model.promoType)">
                  <label class="text-xs color-gray-40 mb-2">Maks. Diskon (Rp)</label>
                  <div class="row gx-3 mb-3">
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-5" name="disc-2" v-model="isMaksDiskonLainnya" :value="15000">
                        <label for="diskon-5" class="cursor-pointer"> {{ 15000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-6" name="disc-2" v-model="isMaksDiskonLainnya" :value="20000">
                        <label for="diskon-6" class="cursor-pointer"> {{ 20000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-7" name="disc-2" v-model="isMaksDiskonLainnya" :value="25000">
                        <label for="diskon-7" class="cursor-pointer"> {{ 25000 | currency }}</label>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 mb-2">
                      <div class="choose-diskon">
                        <input type="radio" class="hidden" id="diskon-8" name="disc-2" v-model="isMaksDiskonLainnya" :value="null">
                        <label for="diskon-8" class="cursor-pointer"> Lainnya</label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="isMaksDiskonLainnya == null">
                      <ValidationProvider name="Maks. diskon (Rp)" v-slot="{ errors }" rules="required|min_value:1" ref="discountPercentageMax">
                        <div class="position-relative">
                          <div class="icn-left-bg icn-left">
                            <vue-numeric @keypress.native="numbersOnly" v-model="model.discountPercentageMax" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" v-if="[3].includes(model.promoType)">
                  <label class="text-xs color-gray-40 mb-2">Diskon Dalam Bentuk</label>
                  <div class="row gx-3">
                    <div class="col-6 mb-2" v-if="selectedProducts.length == 1">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="diskon-type-1" value="fixedPrice" v-model="discountType">
                        <label for="diskon-type-1" class="cursor-pointer"><span class="ms-4">Harga Setelah Diskon</span> </label>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="diskon-type-2" value="percentage" v-model="discountType">
                        <label for="diskon-type-2" class="cursor-pointer"><span class="ms-4">Persentase</span> </label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="discountType == 'fixedPrice' && selectedProducts.length == 1">
                      <ValidationProvider name="Harga Setelah Diskon" v-slot="{ errors }" :rules="{ required: true, max_value: selectedProducts[0].price }" ref="discountPrice">
                        <div class="position-relative">
                          <div class="icn-left-bg icn-left">
                            <vue-numeric @keypress.native="numbersOnly" v-model="fixedPrice" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                    <div class="mb-3" :class="selectedProducts.length == 1 ? 'col-sm-12' : 'col-sm-6'" v-if="discountType == 'percentage'">
                      <ValidationProvider name="Persentase diskon (%)" v-slot="{ errors }" rules="required|min_value:1|max_value:100" ref="discountPercentage">
                        <div class="position-relative">
                          <div class="icn-right-bg icn-right">
                            <input type="text" class="form-control" name="" id="" placeholder="0" value="" v-model="model.discountPercentage">
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="text-sm text-semiBold color-gray-100 mb-3">Durasi & Kuota </div>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Tanggal mulai" v-slot="{ errors }" rules="required" ref="startDate">
                    <div class="mb-3">
                      <label class="text-xs color-gray-40 mb-2">Dari</label>
                      <div class="position-relative">
                        <div class="icn-calendar">
                          <date-picker v-model="model.startDate" :config="datePickerStartDateOptions" placeholder="Pilih Tanggal"></date-picker>
                        </div>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Tanggal selesai" v-slot="{ errors }" rules="required" ref="endDate">
                    <div class="mb-3">
                      <label class="text-xs color-gray-40 mb-2">Hingga</label>
                      <div class="position-relative">
                        <div class="icn-calendar">
                          <date-picker v-model="model.endDate" :config="datePickerEndDateOptions" placeholder="Pilih Tanggal" :disabled="!model.startDate"></date-picker>
                        </div>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12">
                  <label class="text-xs color-gray-40 mb-2">Kuota</label>
                  <div class="row gx-3">
                    <div class="col-6 mb-2">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="kuota-1" name="kuota" v-model="isKuota" :value="true">
                        <label for="kuota-1" class="cursor-pointer"><span class="ms-4">Ada</span> </label>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="choose-user border-gray">
                        <input type="radio" class="hidden" id="kuota-2" name="kuota" v-model="isKuota" :value="false">
                        <label for="kuota-2" class="cursor-pointer"><span class="ms-4">Tidak Ada</span> </label>
                      </div>
                    </div>
                    <div class="col-sm-12 mb-3" v-if="isKuota">
                      <ValidationProvider name="Kuota" v-slot="{ errors }" rules="required|min_value:1" ref="quota">
                        <div class="position-relative">
                          <div class="icn-right-bg icn-pengguna">
                            <vue-numeric @keypress.native="numbersOnly" v-model="model.quota" class="form-control" currency="" separator="." placeholder="0"></vue-numeric>
                          </div>
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End List Product-->

            <div class="col-lg-5">
              <div class="text-sm text-semiBold color-gray-100 mb-3">Ringkasan Promo </div>
              <div class="bg-white rounded-12 mb-3 overflow-hidden" v-if="model.promoType == 3">
                <div class="px-3 py-2 bg-gray-20 text-sm text-medium">{{ selectedProducts.length | numFormat }} Produk dipilih</div>
                <div class="p-3" v-for="product in selectedProducts" :key="product.id">
                  <div class="d-flex align-items-center">
                    <div class="me-3">
                      <img :src="product.images.length > 0 ? product.images[0] : '/img/product-1.png'" class="img-48">
                    </div>
                    <div class="col">
                      <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
                      <ul class="item-info align-items-center mb-0">
                        <!-- <li><span class="text-xs text-hidden">1 Jam 30 Mnt</span> </li> -->
                        <li>
                          <div class="d-flex align-items-center">
                            <div class="text-xs me-1 col-lg-5 col-xl-auto text-hidden color-red-100 text-semiBold">
                              {{ generateDiscount( product ) | currency }}
                            </div>
                            <div class="text-xxs col-lg-4 col-xl text-hidden color-gray-60 text-decoration-line-through">
                              {{ product.price | currency }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-white rounded-12 p-3 mb-3">
                <div class="text-xs mb-2 text-medium color-gray-100 mb-0">Diskon {{ promoTypeText(model.promoType) }}</div>
                <!-- <div class="col-6 text-xs color-gray-80 text-hidden">Promo ID #dc35c5</div> -->
                <hr class="dashed">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Target</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100"> {{ kfs.name }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" v-if="[1.2].includes(model.promoType)">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Kode Voucher</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.voucherCode ? model.voucherCode.toUpperCase() : '-' }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" v-if="[1.2].includes(model.promoType)">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Minimal Pembelian (Rp)</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.minimumPayment | currency }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.promoType == 1">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Nominal DIskon (Rp)</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPrice | currency }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.promoType == 2">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Persentase Diskon (%)</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPercentage | numFormat }}%</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.promoType == 2">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Maks. Diskon (Rp)</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.discountPercentageMax | currency }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Durasi</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-if="model.startDate && model.endDate">{{ model.startDate | moment('DD/MM/YYYY') }} - {{ model.endDate | moment('DD/MM/YYYY') }}</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100" v-else>-</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-0">
                  <div class="col-6 text-xs color-gray-80 text-hidden">Kuota (pengguna)</div>
                  <div class="col-6 text-xs text-right text-hidden text-medium color-gray-100">{{ model.quota | numFormat }}</div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary w-100">Konfirmasi & Ajukan Promo</button>
            </div>
            <!--End Right Side Bar-->
          </div>
        </section>
      </form>
    </ValidationObserver>
    <!-- <json-viewer :value="request" :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: null,
      kfs: {},

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',
      selectedProducts: [],

      model: {
        kfsId: parseInt(this.$route.params.id),
        name: null,
        voucherCode: null,
        promoType: parseInt(this.$route.query.promoType),
        minimumPayment: 0,
        discountPrice: '',
        discountPercentage: '',
        discountPercentageMax: '',
        startDate: null,
        endDate: null,
        quota: 0,
        status: true,
        allTargets: false,
        products: []
      },

      isMinimalPembelian: false,
      isNominalDiskonLainnya: null,
      isPersentaseDiskonLainnya: null,
      isMaksDiskonLainnya: null,
      isKuota: false,

      datePickerStartDateOptions: {
        minDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },

      discountType: 'fixedPrice',
      fixedPrice: ''
    }
  },
  computed: {
    request: function() {
      let _ = this
      let targets = []
      targets.push(_.$route.params.id)

      let discountPrice = _.model.discountPrice ? parseInt(_.model.discountPrice) : 0
      if ( _.model.promoType == 3 ) {
        if (_.discountType == 'fixedPrice' && _.selectedProducts.length > 0) {
          discountPrice = _.selectedProducts[0].price - _.fixedPrice
        }
      }

      return {
        ...this.model,
        startDate: _.model.startDate + ' 00:00:00',
        endDate: _.model.endDate + ' 23:59:59',
        products: _.selectedProducts.map( x => x.accuId ),
        discountPrice: discountPrice,
        discountPercentage: _.model.discountPercentage ? parseInt(_.model.discountPercentage) : 0,
        discountPercentageMax: _.model.discountPercentageMax ? parseInt(_.model.discountPercentageMax) : 0,
        promotionTargets: targets,
        quota: _.model.quota > 0 ? _.model.quota : null
      }
    },
    datePickerEndDateOptions: function() {
      return {
        minDate: this.model.startDate,
        format: 'YYYY-MM-DD',
        useCurrent: false,
      }
    }
  },
  methods: {
    getProduct( page ) {
      let _ = this
      _.axios.get('/kfs-inventory'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
          + '&kfsId=' + _.$route.params.id
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.products.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchProduct() {
      let _ = this
      _.products = []
      _.getProduct(1)
    },
    showKfs() {
      let _ = this

      _.axios.get('/kfs/' + _.$route.params.id)
        .then( resp => {
          _.kfs = resp.data.data
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    async store() {
      let _ = this 
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.axios.post('/kfs-promotion', _.request)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.back()
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$notify({
              type: 'error',
              title: 'Terjadi Kesalahan ('+err.response.data.code+')',
              text: err.response.data.message
            });
          }
        })
    },
    addOrRemoveProduct( product, event ){
      let _ = this
      if (event.target.checked) {
        _.selectedProducts.push(product)                
      } else {
        const index = _.selectedProducts.map( x => x.id ).indexOf( product.id );
        if (index > -1) {
          _.selectedProducts.splice(index, 1);
        }
      }
    },
    removeProduct( product ) {
      let _ = this
      const index = _.selectedProducts.map( x => x.id ).indexOf( product.id );
      if (index > -1) {
        _.selectedProducts.splice(index, 1);
      }
    },
    generateDiscount( product ) {
      if ( parseInt(product.price) <= 0 ) return 0
      
      let totalDiscount = 0

      if ( this.discountType == 'percentage' ) {
        totalDiscount = parseFloat(product.price) * this.model.discountPercentage / 100 
        let finalResult = parseFloat(product.price) - totalDiscount
        if ( finalResult >= 0 ) {
          return finalResult
        }
        return 0
      } else {
        return this.fixedPrice
      }
    }
  },
  mounted() {
    let _ = this
    _.showKfs()
    if ( _.model.promoType == 3 ) {
      _.state = 'products'
      _.getProduct(1)
    } else {
      _.state = 'requirements'
    }
  },
  watch: {
    isMinimalPembelian: function() {
      this.model.minimumPayment = this.isMinimalPembelian ? '' : 0
    },
    isNominalDiskonLainnya: function() {
      this.model.discountPrice = this.isNominalDiskonLainnya ? this.isNominalDiskonLainnya : ''
    },
    isPersentaseDiskonLainnya: function() {
      this.model.discountPercentage = this.isPersentaseDiskonLainnya
    },
    isMaksDiskonLainnya: function() {
      this.model.discountPercentageMax = this.isMaksDiskonLainnya ? this.isMaksDiskonLainnya : ''
    },
    isKuota: function() {
      if (!this.isKuota) {
        this.model.quota = 0
      } else {
        this.model.quota = ''
      }
    },
    'model.startDate': function() {
      this.model.endDate = ''
    },
    selectedProducts: function() {
      if (this.selectedProducts.length > 1) {
        this.discountType = 'percentage'
      } else {
        this.discountType = 'fixedPrice'
      }
    },
    discountType: function() {
      this.model.discountPercentage = ''
      this.fixedPrice = ''
    }
  }
}
</script>