<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center mb-4">Lupa Password</h6>
    <a @click="$router.back()" class="text-lg d-block text-bold mb-3"><strong><span
          class="icon-ico-back"></span></strong></a>
    <p class="text-sm color-gray-100 mb-4">Tuliskan alamat email Anda dan kami akan mengirimkan kode
      verifikasi untuk mengatur ulang kata sandi baru.</p>

      <ValidationObserver ref="observer">
        <form @submit.prevent="forgotPassword()">
          <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
            <div class="form-group auth mb-4">
              <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Email</label>
              <div class="position-relative">
                <input type="email" class="form-control" placeholder="" name="" v-model="model.email">
              </div>
              <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <button type="submit" class="btn btn-primary w-100">Selanjutnya</button>
        </form>
      </ValidationObserver>

      <p class="text-center mb-0 mt-1"> <small class="text-danger text-sm text-xs">{{errorMsg}}</small></p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        email: null
      },
      errorMsg: null
    }
  },
  methods: {
    async forgotPassword() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return
      
      _.axios.post('/auth/forgot-password', { email: _.model.email.toLowerCase() })
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.push('/verification?email='+ _.model.email.toLowerCase() +'&otpToken=' + resp.data.data.otpToken)
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.errorMsg = err.response.data.message
          }
        })
    }
  },
}
</script>