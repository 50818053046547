<template>
  <div id="pilih-tanggal" class="box-xs bg-white rounded-12 p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-semiBold">Pilih Tanggal</div>
      <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
    </div>
    <div class="content-popup mb-3">
      <div class="d-flex align-items-center px-2" style="justify-content: space-around;">
        <div class="text-sm w-50px">Tanggal</div>
        <div class="text-sm w-50px">Bulan</div>
        <div class="text-sm w-50px">Tahun</div>
      </div>
      <date-picker-drum class="center mt-4" v-model="date"
        :format="'YYYY-MM-DD'"
        :pattern="{ 
          dividerDate: '',
          month: 'MMM'
        }" 
        :dateOrder="['date', 'month', 'year']"
        :height="'150px'"/>
    </div>

    
    <button class="btn btn-primary w-100 close-btn" type="button" @click="saveDate()">Terapkan</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    saveDate() {
      this.$root.$emit('setDate', this.date)
    },
    cancel() {
      let _ = this
      if ( !_.selectedKfsId && _.selectedKfsId != _.tempSelectedKfsId ) {
        _.tempSelectedKfsId = null
      } else {
        _.tempSelectedKfsId = _.selectedKfsId
      }
    }
  },
  mounted() {
    this.$root.$on('setInitialDate', (data) => {
      this.date = data
    })
  },
}
</script>