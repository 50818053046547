<template>
  <div>
    <div class="bottom mt-2">
      <div class="dataTables_length" id="customer-list_length">
        <label>
          Item per halaman 
          <select name="customer-list_length" aria-controls="customer-list" class="form-select data" v-model="limit">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select> 
        </label>
      </div>
      <div id="customer-list_paginate">
        <ul class="pagination-table">
          <li class="paginate_button page-item previous" id="customer-list_previous" :class="{ disabled: data.currentPage <= 1 }">
            <a href="#" class="page-link" @click.prevent="changePage(data.currentPage - 1)"><span class="icon-ico-chev-left"></span></a>
          </li>
          <li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
            <a href="#" class="page-link" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="paginate_button page-item next" id="customer-list_next" :class="{ disabled: data.currentPage >= data.totalPages }">
            <a href="#" class="page-link" @click.prevent="changePage(data.currentPage + 1)"><span class="icon-ico-chev-right"></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: [ 'data' ],
	data() {
		return {
			limit: 10
		}
	},
	methods: {
		isCurrentPage(page){
			return this.data.currentPage === page
		},
		changePage(page) {
			if (page > this.data.totalPages) {
				page = this.data.totalPages;
			}
			this.data.currentPage = page;
			this.$root.$emit('changePage', page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.currentPage
			let last            = this.data.totalPages
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	},
	watch: {
		limit: function(){
			let _ = this
			_.$root.$emit('changeLimit', _.limit)
		}
	}
}
</script>